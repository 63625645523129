enum MenuNodeId {
  Forms = 'd531b004-de71-4747-a911-516bf7c5a6cc',
  IntentionGroups = 'ad3cc60a-9fd9-4326-bb92-ca655c5039a4',
  Groups = '26ad3c9e-c26e-4bda-9b41-e8a2555f8716',
  GroupControl = 'b6f5441d-349e-44ae-92e0-27965b62d713',
  GroupCuratorChat = '61043c28-2750-408b-8f90-73c68584f91d',
  MyCourses = '0c8cc7b8-75cf-4c9f-a70f-03e4c1f6db66',
  MyCoursesCuratorChat = 'd66489e1-32a9-4060-b70e-2858e0e622a0',
  MyCoursesSummary = '839e09f1-d2ba-47c9-a959-7b1156e1df4e',
  MyCoursesQuestions = 'e0adc51f-63e3-46ab-958f-f283c4aab7e1',
  StudentInvitationCalendar = 'b88c38e7-4e6e-4bc7-a7b4-b2db7a2bad1d',
}

export default MenuNodeId;

// Огоньки нотификация на меню
