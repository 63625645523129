import http from '../httpService';
import { GetFileClientDto, GetFileClientOutputDto } from './dto';

class Index {
  public async image(e: GetFileClientDto): Promise<GetFileClientOutputDto | undefined> {
    try {
      if (!e.id || e.id == null) {
        throw new Error('Invalid ID');
      }

      const result = await http.get('/api/file/client/image', { params: e });

      return result.data;
    } catch (error) {
      console.error('FileClientService.image', error);
    }
  }

  public async withStream(id?: string): Promise<string> {
    const result = await http.get(`/api/file/client/with-stream?id=${id}`);
    return result.data;
  }

  public async getVideoToken(): Promise<string> {
    const result = await http.get('/api/file/client/stream-token');
    return result.data;
  }
}

export default new Index();
