import { Popover } from 'antd';
import { useState } from 'react';
import IconSVG from '../../../components/IconSvg';
import { useAuthStore, useLocale } from '../../../App';
import styles from './index.module.css';
import IconMenuDown from '../../../assets/icons/icon_menu_down.svg';
import { useLanguageStore } from '../../../stores/langStore';

const Index = () => {
  const { authStore } = useAuthStore();
  const { langStore } = useLanguageStore();
  const [isOpened, setOpened] = useState(false);

  const { lang } = useLocale();

  const select = async (e: any) => {
    await lang.setLanguage({
      culture: e.cultureName,
      uiCulture: e.uiCultureName,
    });
    await langStore.getLocale();
  };

  const handleOpen = () => {
    setOpened(!isOpened);
  };

  return (
    <div className={styles.lang}>
      <Popover
        placement="bottom"
        trigger="click"
        overlayInnerStyle={{ padding: 0 }}
        open={isOpened}
        onOpenChange={handleOpen}
        content={(
          <div className={styles.languages}>
            {authStore.app?.localization.languages.map((e) => (
              <p
                key={e.cultureName}
                onClick={() => !langStore.isLoading && select(e)}
              >
                {e.uiCultureName}
              </p>
            ))}
          </div>
        )}
      >
        <div className={styles.language}>
          {localStorage.getItem('Locale') ?? 'en'}
          <IconSVG src={IconMenuDown} />
        </div>
      </Popover>
    </div>
  );
};

export default Index;
