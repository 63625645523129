import { createContext } from 'react';
import Lang from '../utils/lang/lang';

export type LangContextType = {
  lang: Lang;
};

const lang = new Lang();

export const LangStoreContext = createContext<LangContextType>({
  lang: lang,
});

//

export type CoreHrTranslationKeys = typeof lang.CoreHr;
export type LmsTranslationKeys = typeof lang.Lms;
export type HcmTranslationKeys = typeof lang.Hcm;
export type TraforyTranslationKeys = typeof lang.Trafory;
