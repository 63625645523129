export function getHostname(): string {
  const { REACT_APP_HOSTNAME } = process.env;
  if (REACT_APP_HOSTNAME && REACT_APP_HOSTNAME !== 'REPLACE_WITH_HOSTNAME') {
    return REACT_APP_HOSTNAME;
  }
  // Provide a fallback or default hostname if needed
  return 'https://localhost:44363';
}

// files //

export const maxSizeOfDownloadedFiles = 1.5; // 1.5 Gb //

export const maxSizeOfDownloadedFilesInAssignment = 50; // 50 Mb //

// post //

export const countOfPopularTags = 5;

export const maxLengthOfPostTitle = 100;

export const maxLengthOfPostNameAuthor = 100;

export const maxLengthOfPostShortDescription = 500;

// competence //

export const maxLengthOfCompetencyOrQualityName = 100;

export const maxLengthOfComponentOfCompetence = 100;

export const maxLengthOfCompetenceLevelDescription = 500;

// images //

export enum PossibleImageDemensions {
  avatarSmallSize = '32-32',
  avatarMiddleSize = '100-100',
  avatarBigSize = '300-300',
  avatarLargeSize = '500-500',
  middleImageDimension = '1024-576',
  bigImageDimension = '1920-1080',
}

export const recommendedAspectRatio = '16:9';
