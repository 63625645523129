export const validatePassword = (password?: string): boolean => {
  if (!password) {
    return true;
  }
  const result = validatePasswordPattern(password);
  const isValid = result.minLength && result.hasLowercase && result.hasUppercase && result.hasSpecialCharacter;
  return isValid;
};

export const allowLatin = (text: string): boolean => {
  const latinRegex = /^[a-zA-Z]+$/;

  const combinedRegex = new RegExp(latinRegex);

  return combinedRegex.test(text);
};

const validatePasswordPattern = (password: string): PasswordValidationResult => ({
  minLength: password.length >= 8,
  hasLowercase: /[a-z]/.test(password),
  hasUppercase: /[A-Z]/.test(password),
  hasSpecialCharacter: /[~!?#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?@]+/.test(password),
});

type PasswordValidationResult = {
  minLength: boolean;
  hasLowercase: boolean;
  hasUppercase: boolean;
  hasSpecialCharacter: boolean;
};
