import { createContext } from 'react';
import AuthStore from '../stores/authStore';

export type AuthContextType = {
  authStore: AuthStore;
};

/**
 * TODO: Rework all mobx+context
 */

export const AuthStoreIns = new AuthStore();

export const AuthStoreContext = createContext<AuthContextType>({
  authStore: AuthStoreIns,
});
