// Belarusian [be]
import dayjs from 'dayjs';

const localeBe = {
  name: 'be',
  weekdays: 'нядзеля_панядзелак_аўторак_серада_чацвер_пятніца_субота'.split('_'),
  months: 'студзень_люты_сакавік_красавік_травень_чэрвень_ліпень_жнівень_верасень_кастрычнік_лістапад_снежань'.split('_'),
  weekStart: 1,
  weekdaysShort: 'нд_пн_ат_ср_чц_пт_сб'.split('_'),
  monthsShort: 'студ_лют_сак_крас_трав_чэрв_ліп_жнів_вер_каст_ліст_снеж'.split('_'),
  weekdaysMin: 'нд_пн_ат_ср_чц_пт_сб'.split('_'),
  ordinal: (n:any) => n,
  formats: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D MMMM YYYY г.',
    LLL: 'D MMMM YYYY г., HH:mm',
    LLLL: 'dddd, D MMMM YYYY г., HH:mm',
  },
  relativeTime: {
    future: 'праз %s',
    past: 'калісь %s',
    s: 'несколькі секунд',
    m: 'хвіліна',
    mm: '%d хвілін',
    h: 'гадзіна',
    hh: '%d гадзін',
    d: 'дзень',
    dd: '%d дзён',
    M: 'месяц',
    MM: '%d месяцаў',
    y: 'год',
    yy: '%d гадоў',
  },
};

dayjs.locale(localeBe, undefined, true);

export default localeBe;
