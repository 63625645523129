import { Button, Image, Modal, Tour } from 'antd';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useGuideRefs } from '../../../../context/guideRefsContextType';
import { useAuthStore, useLocale } from '../../../../App';
import TraforyEntry from '../../../../assets/system/trafory/entry.png';
import { personManagementStore } from '../../../Core/stores/personManagementStore';
import { EmployeeIntroType } from '../../../../enum/PersonManagement';

import mainStyles from '../../../../customization/customization.module.css';
import styles from './UserGuide.module.css';
import AppConsts from '../../../../libs/AppConsts';

export const UserGuide = observer(() => {
  const { lang }: any = useLocale();
  const { guideRefs } = useGuideRefs();
  const [isUserGuideStarted, setUserGuideStarted] = useState(false);
  const [isHover, setHover] = useState(true);
  const [isLastStep, setLastStep] = useState(false);
  const { authStore } = useAuthStore();

  const isIntroCompleted = authStore.permission?.watchedIntroInfo.lmsIntro;

  const resetDefaultHover = () => {
    setHover(false);
  };

  const returnDefaultHover = () => {
    setHover(true);
  };

  const steps: any = guideRefs.map(({ id, ref }: any) => {
    let currentPlacement;

    switch (id) {
      case 4:
        currentPlacement = 'rightBottom';
        break;
      case 5:
        currentPlacement = 'bottomLeft';
        break;
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        currentPlacement = 'rightTop';
    }

    return ({
      title: lang.translate(`UserGuideStep${id + 1}Title_UI`),
      description: lang.translate(`UserGuideStep${id + 1}Description_UI`),
      prevButtonProps: { style: { display: 'none' } },
      nextButtonProps: {
        className: classNames(styles.step_button, {
          [styles.default_hover]: isHover,
        }),
        type: 'link',
        children: isLastStep ? lang.Lms.done : lang.CoreHr.proceed,
        disabled: personManagementStore.isLoading,
      },
      stepRender: (current: number, total: number) => (
        <div className={styles.step_footer}>
          <div className={styles.step_info}>
            {lang.Lms.stepOf.replace('{current}', current + 1).replace('{total}', total)}
          </div>
          <Button disabled={personManagementStore.isLoading} onMouseEnter={resetDefaultHover} onClick={finishGuide} type="link" className={styles.step_button}>
            {lang.Lms.skip}
          </Button>
        </div>
      ),
      arrow: false,
      target: ref?.current,
      placement: currentPlacement,
    });
  }).filter((i) => i.target !== null);

  const handleChange = (step: number) => {
    if (step + 1 === steps.length) {
      setLastStep(true);
    }

    returnDefaultHover();
  };

  const startGuide = () => {
    document.body.classList.add(mainStyles.no_pointer_events);
    setUserGuideStarted(true);
  };

  const finishGuide = async () => {
    await personManagementStore.setIntroStatus(EmployeeIntroType.Lms, true);
    if (authStore.permission) {
      authStore.permission.watchedIntroInfo.lmsIntro = true;
    }
    document.body.classList.remove(mainStyles.no_pointer_events);
    setLastStep(false);
    returnDefaultHover();
  };

  useEffect(() => {
    if (AppConsts.isIntroHidden) {
      setUserGuideStarted(false);
      finishGuide();
    }
  }, [AppConsts.isIntroHidden]);

  useEffect(() => {
    if (isIntroCompleted) {
      authStore.permission!.watchedIntroInfo.lmsIntro = true;
      setUserGuideStarted(false);
    }
  }, [isIntroCompleted]);

  if (AppConsts.isIntroHidden && authStore.permission!.watchedIntroInfo.lmsIntro) {
    return null;
  }

  return (
    <>
      <Modal
        open={!isUserGuideStarted && !isIntroCompleted && !authStore.isAdmin}
        footer={false}
        closeIcon={<div />}
        className={classNames('user-guide-modal', styles.modal)}
        width={634}
      >
        <div className={styles.modal_inner_content}>
          <Image className={styles.logo} preview={false} src={TraforyEntry} />
          <div className={styles.divider} />
          <div className={styles.title}>
            {lang.Lms.welcomeToTrafory}
          </div>
          <div className={styles.description}>
            {lang.Lms.traforyWelcomeDescription}
          </div>
          <Button type="primary" className={styles.start_button} onClick={startGuide}>
            {lang.Lms.letsStart}
          </Button>
        </div>
      </Modal>
      <Tour
        onFinish={finishGuide}
        onChange={handleChange}
        open={isUserGuideStarted && !isIntroCompleted}
        steps={steps}
      />
    </>
  );
});
