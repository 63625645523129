import { observer } from 'mobx-react';
import { Badge, Col, Modal, Popover, Row } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import IconExpand from '../../assets/icons/drawer_open.svg';
import IconCollapse from '../../assets/icons/drawer_close.svg';
// import IconAttention from '../../assets/icons/Attention.svg';
import IconSVG from '../IconSvg';
import IconModules from '../../assets/icons/icon_modules.svg';
import Styles from '../../utils/styles';
import Core from './assets/core.svg';
import Client from './assets/client.svg';
import IconBell from '../../assets/icons/icon_bell.svg';
import { useNotificationStore, useSidebarStore } from '../../Navigation';
import { useAuthStore, useLocale } from '../../App';
import IconMenuDown from '../../assets/icons/icon_menu_down.svg';
import IconExit from '../../assets/icons/icon_exit.svg';
import IconUserCircle from '../../assets/icons/icon_user_circle.svg';
import NotificationsView from '../Notifications';
import { Language } from '../../models/AppConfig';
import Picture from '../Elements/Picture';
import { useLanguageStore } from '../../stores/langStore';
import Utils from '../../utils/utils';
import { useGuideRefs } from '../../context/guideRefsContextType';

import styles from './index.module.css';
import SidebarState from '../../enum/SidebarState';
import { UserGuide } from '../../projects/Lms/components/UserGuide/UserGuide';
// import { personManagementStore } from '../../projects/Core/stores/personManagementStore';
// import { EmployeeIntroType } from '../../enum/PersonManagement';
import { appSettingsStore } from '../../stores/traforySettingStore';
import BackButton from '../Elements/BackButton';
import { PossibleImageDemensions } from '../../config';

const Index = observer(() => {
  const { sideStore } = useSidebarStore();
  const { guideRefs } = useGuideRefs();
  const { authStore } = useAuthStore();

  const isIntroCompleted = authStore.permission?.watchedIntroInfo.lmsIntro;

  const location = useLocation();
  const nav = useNavigate();

  // Hides the collapse menu if we are on a lesson
  const isLesson = location.pathname.includes('lesson-passing');
  const isCreateCoursePage = location.pathname.includes('constructor/');
  const isCreateLessonPage = location.pathname.includes('constructor/lesson/');

  useEffect(() => {
    if (!isIntroCompleted) {
      sideStore.state = SidebarState.open;
    }
  }, [isIntroCompleted]);

  return (
    <header className={styles.header}>
      <div className={styles.buttonWrapper}>
        <div ref={guideRefs[5].ref} className={styles.expanded} onClick={() => sideStore.toggle()} style={{ opacity: isLesson ? 0 : 1, height: '29px' }}>
          <IconSVG isDefault src={sideStore.isExpanded ? IconExpand : IconCollapse} />
        </div>
        {isCreateCoursePage && <BackButton size='small' className={isCreateLessonPage ? styles.backButtonHidden : styles.backButton} onClick={() => nav(-1)} />}
      </div>
      <div className={styles.actions}>
        <LanguageSelector />
        <ModeSelector />
        <Notification />
        <Avatar />
      </div>
      <NotificationsView />
      <UserGuide />
    </header>
  );
});

export default Index;

// Колокольчик, открывает уведомления
const Notification = observer(() => {
  const { notificationStore } = useNotificationStore();
  const { guideRefs } = useGuideRefs();

  const open = async () => notificationStore.getList();

  return (
    // eslint-disable-next-line
    <Badge count={notificationStore.totalUnread} dot size='small' offset={[-7, 5]}>
      <div ref={guideRefs[1].ref} className={styles.bell} onClick={open}>
        <IconSVG src={IconBell} color={Styles.night900} />
      </div>
    </Badge>
  );
});

// Выбор языка
const LanguageSelector = observer(() => {
  const { authStore } = useAuthStore();
  const { langStore } = useLanguageStore();
  const { sideStore } = useSidebarStore();
  const [isOpened, setOpened] = useState(false);
  const { lang } = useLocale();
  const { guideRefs } = useGuideRefs();

  const select = async (e: Language) => {
    setOpened(false);
    await lang.setLanguage({
      culture: e.cultureName,
      uiCulture: e.uiCultureName,
    });
    await langStore.getLocale();
    authStore.setLocale(e.cultureName);
    sideStore.setHalf();
  };

  const handleOpen = () => {
    setOpened(!isOpened);
  };

  return (
    <Popover
      placement='bottom'
      trigger="click"
      overlayInnerStyle={{ padding: 0 }}
      open={isOpened}
      onOpenChange={handleOpen}
      content={
        <div className={styles.languages}>
          {authStore.app?.localization.languages.map((e, idx) => (
            <p
              key={idx}
              onClick={() => !langStore.isLoading && select(e)}
            >
              {e.uiCultureName}
            </p>
          ))}
        </div>
      }
    >
      <div ref={guideRefs[3].ref} className={styles.language}>
        {localStorage.getItem('Locale') ?? 'en'}
        <IconSVG src={IconMenuDown} />
      </div>
    </Popover>
  );
});

// Выбор к какой системе работать, Core HR или пользовательская часть
const ModeSelector = observer(() => {
  const location = useLocation();
  const nav = useNavigate();
  const { authStore } = useAuthStore();
  const { lang } = useLocale();
  const { guideRefs } = useGuideRefs();
  const [isPopoverOpened, setPopoverOpened] = useState(false);

  const modules = [
    {
      title: lang.CoreHr.adminPanel,
      logo: Core,
      color: Styles.secondary,
      src: 'admin',
    },
    {
      title: lang.CoreHr.clientApp,
      color: Styles.primary,
      logo: Client,
      src: 'client',
    },
  ];

  const system = location.pathname.split('/')[1];

  const select = (title: string) => {
    const getData = async () => {
      await authStore.getUserPermissions();
      await appSettingsStore.read();
      authStore.isAdmin = title === 'admin';
      localStorage.setItem('isAdmin', String(title === 'admin'));

      if (appSettingsStore.data) {
        Utils.setStyles(
          appSettingsStore.data.primaryColor,
          appSettingsStore.data.secondaryColor,
          appSettingsStore.data.linksColor,
          appSettingsStore.data.defaultFont,
        );
      }
    };

    nav(title === 'admin' ? '/admin' : '/');
    getData();
  };

  const showAccessError = () => Modal.warning({
    title: lang.Lms.attention,
    content: lang.CoreHr.youHaveNotAccessToFunctionality,
    cancelButtonProps: { style: { display: 'none' } },
    okText: lang.Lms.understand,
  });

  return (
    <Popover
      placement='bottom'
      trigger='click'
      overlayInnerStyle={{ padding: 0 }}
      open={isPopoverOpened}
      onOpenChange={() => { setPopoverOpened(!isPopoverOpened); }}
      content={
        <div className={styles.modules}>
          <Row>
            {modules.map((e, idx) => (
              <Col
                style={{ borderColor: e.src === system ? 'var(--primary)' : '' }}
                key={idx}
                span={12}
                className={styles.module}
                onClick={() => {
                  if (e.src === 'admin' && !authStore.canAccessCoreHr) {
                    showAccessError();
                  } else {
                    select(e.src);
                  }

                  setPopoverOpened(false);
                }}
              >
                <div style={{
                  // padding: 4,
                  height: 48,
                  width: 48,
                  filter: e.src === 'admin' && !authStore.canAccessCoreHr
                    ? 'grayscale(1) brightness(1.2)'
                    : undefined,
                  borderRadius: 10,
                  backgroundColor: e.color,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                >
                  <img src={e.logo} alt={e.title} />
                </div>
                <p>{e.title}</p>
              </Col>
            ))}
          </Row>
        </div>
      }
    >
      <div ref={guideRefs[0].ref} className={styles.selector}>
        <IconSVG src={IconModules} color={Styles.night900} />
      </div>
    </Popover>
  );
});

// Аватарка и меню
const Avatar = observer(() => {
  const { lang } = useLocale();
  const { authStore } = useAuthStore();
  const nav = useNavigate();

  const exit = () => {
    appSettingsStore.resetDesign();
    appSettingsStore.data = undefined;
    authStore.logout();
  };

  const { guideRefs } = useGuideRefs();

  const openProfile = () => {
    const personId = authStore.permission?.personInfoUser?.id;
    nav(`/employees/profile/${personId}/profile`);
  };

  // const showIntro = async () => {
  //   await personManagementStore.setIntroStatus(EmployeeIntroType.Lms, false);
  //   if (authStore.permission) {
  //     authStore.permission.watchedIntroInfo.lmsIntro = false;
  //   }
  // };

  return (
    <Popover
      trigger='click'
      placement='bottomRight'
      overlayInnerStyle={{ padding: 4 }}
      content={
        <div>
          <div onClick={openProfile} className={styles.menu_item}>
            <IconSVG src={IconUserCircle} />
            {lang.CoreHr.profile}
          </div>
          {/* {localStorage.getItem('isAdmin') !== 'true' && (
            <div
              onClick={!personManagementStore.isLoading ? showIntro : undefined}
              className={styles.menu_item}
            >
              <IconSVG src={IconAttention} />
              {lang.Lms.hints}
            </div>
          )} */}
          <div onClick={exit} className={styles.menu_item}>
            <IconSVG src={IconExit} />
            {lang.CoreHr.logout}
          </div>
        </div>
      }
    >
      <div ref={guideRefs[2].ref} className={styles.trailing}>
        <div className={styles.user}>
          <div className={styles.name}>
            <h6>{authStore.permission?.personInfoUser?.firstName}</h6>
            <h6>{authStore.permission?.personInfoUser?.lastName}</h6>
          </div>
          <Picture src={authStore.permission?.personInfoUser?.avatar} size={PossibleImageDemensions.avatarBigSize} />
        </div>
      </div>
    </Popover>
  );
});
