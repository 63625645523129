import { Localization } from '../../models/AppConfig';
import { SetLanguageDto } from '../../services/ConfigService/dto';
import { CoreHrLocaleKeys } from './locale-keys/CoreHr';
import { LmsLocaleKeys } from './locale-keys/Lms';
import { HcmLocaleKeys } from './locale-keys/Hcm';
import { TraforyLocaleKeys } from './locale-keys/Trafory';
import { LicenseLocaleKeys } from './locale-keys/License';
import { SMLocaleKeys } from './locale-keys/SM';

class Lang {
  data?: Localization;

  constructor(e?: Localization) {
    this.data = e;
    if (this.data?.resources) {
      const { CoreHr, Lms, Hcm, Trafory, License, SM } = this.data.resources;
      localStorage.setItem('translations', JSON.stringify({ CoreHr, Lms, Hcm, Trafory, License, SM }));
    }
  }

  static get locale() {
    return localStorage.getItem('Locale') ?? 'ru';
  }

  static get translations() {
    const data = localStorage.getItem('translations');

    if (data) {
      return JSON.parse(data);
    }

    return null;
  }

  async setLanguage(e: SetLanguageDto) {
    localStorage.setItem('Locale', e.culture);
  }

  translate = (val: string, module: 'CoreHr' | 'Hcm' | 'Lms' | 'Trafory' | 'License' | 'SM' = 'Lms') => {
    const res = this.data?.resources[module].texts[val];
    return res ?? val;
  };

  get CoreHr(): Record<string, string> {
    const T = (val: string) => this.translate(val, 'CoreHr');

    return CoreHrLocaleKeys(T);
  }

  get Lms(): Record<string, string> {
    const T = (val: string) => this.translate(val, 'Lms');

    return LmsLocaleKeys(T);
  }

  get Hcm(): Record<string, string> {
    const T = (val: string) => this.translate(val, 'Hcm');

    return HcmLocaleKeys(T);
  }

  get Trafory(): Record<string, string> {
    const T = (val: string) => this.translate(val, 'Trafory');

    return TraforyLocaleKeys(T);
  }

  get License(): Record<string, string> {
    const T = (val: string) => this.translate(val, 'License');

    return LicenseLocaleKeys(T);
  }

  get SM(): Record<string, string> {
    const T = (val: string) => this.translate(val, 'SM');

    return SMLocaleKeys(T);
  }
}

export default Lang;
