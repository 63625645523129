import { PagedResultDto } from '../../models/PagedResultDto';
import { UserNotification } from '../../models/UserNotification';
import API from '../Api';
import http from '../httpService';
import { GetPagedUserNotificationListDto } from './dto';

class Index {
  public async read(id?: string): Promise<UserNotification> {
    const result = await http.get(API.LMS.UserNotifications + id);
    return result.data;
  }

  public async getList(e: GetPagedUserNotificationListDto): Promise<PagedResultDto<UserNotification>> {
    const result = await http.get(`${API.LMS.UserNotifications}list`, { params: e });
    return result.data;
  }

  public async getUnreadNumber(): Promise<number> {
    const result = await http.get(`${API.LMS.UserNotifications}unread-number`);
    return result.data;
  }

  public async markAsRead(id?: string | string[]): Promise<boolean> {
    try {
      if (id == null) {
        throw new Error();
      }

      const data = typeof id === 'string' ? [id] : id;

      const result = await http.put(`${API.LMS.UserNotifications}mark-as-read`, data);

      return result.status === 200;
    } catch (error) {
      console.error('UserNotificationService.markAsRead', error);

      return false;
    }
  }
}

export default new Index();
