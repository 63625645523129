import { Outlet, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import NavScaffold from '../NavScaffold/NavScaffold';
import Route from '../../models/Route';
import IconSettings from '../../assets/icons/icon_setting_line.svg';
import IconCompany from '../../assets/icons/icon_company.svg';
import IconDashboard from '../../assets/icons/icon_dashboard.svg';
import IconDirectory from '../../assets/icons/icon_directory.svg';
import IconUsers from '../../assets/icons/icon_student.svg';
import IconFiles from '../../assets/icons/icon_folder_file.svg';
import { useAuthStore, useLocale } from '../../App';
import Pages from '../../enum/Pages';
import IconPolls from '../../assets/icons/icon_polls.svg';
import MenuNodeId from '../../enum/MenuNodeId';
import CircleNotificationNodeType from '../../enum/CircleNotificationNodeType';
import useGetCircles from '../../services/CircleNotifications/useGetCircles';
import useGetCirclesList from '../../services/CircleNotifications/useGetCirclesList';
import { GetCircleNotificationListDto } from '../../services/CircleNotifications/dto';
import IconGrade from '../../assets/icons/icon_grade.svg';
import IconCourse from '../../assets/icons/icon_course.svg';
import IconMedal from '../../assets/icons/icon_medal.svg';
import IconAdaptation from '../../assets/icons/icon_adaptation.svg';
import IconWorkspace from '../../assets/icons/icon_workspace.svg';
import IconLearningCenter from '../../assets/icons/icon_learning_center.svg';

const circlesDto: GetCircleNotificationListDto = {
  inputNodes: [
    {
      itemId: MenuNodeId.Groups,
      nodeType: CircleNotificationNodeType.MenuGroups,
    },
    {
      itemId: MenuNodeId.MyCourses,
      nodeType: CircleNotificationNodeType.MenuMyCourses,
    },
    {
      itemId: MenuNodeId.IntentionGroups,
      nodeType: CircleNotificationNodeType.MenuIntentionGroups,
    },
    {
      itemId: MenuNodeId.StudentInvitationCalendar,
      nodeType: CircleNotificationNodeType.StudentInvitationCalendar,
    },
  ],
};

function Index() {
  const { lang } = useLocale();

  const { authStore } = useAuthStore();

  const navigate = useNavigate();

  const formCircles = useGetCircles({
    data: {
      ItemId: MenuNodeId.Forms,
      NodeType: CircleNotificationNodeType.MenuForms,
    },
  });

  const circles = useGetCirclesList({ data: circlesDto });

  const filteredCircles = (itemId: string, nodeType: CircleNotificationNodeType) => {
    if (circles) {
      const result = circles.find((e) => e.personalCircleNotifications.itemId === itemId && e.personalCircleNotifications.nodeType === nodeType);
      return result?.personalCircleNotifications.total;
    }
    return undefined;
  };

  const groups = filteredCircles(MenuNodeId.Groups, CircleNotificationNodeType.MenuGroups);

  const courses = filteredCircles(MenuNodeId.MyCourses, CircleNotificationNodeType.MenuMyCourses);

  const intentionGroups = filteredCircles(MenuNodeId.IntentionGroups, CircleNotificationNodeType.MenuIntentionGroups);

  const intentionInvitations = filteredCircles(MenuNodeId.StudentInvitationCalendar, CircleNotificationNodeType.StudentInvitationCalendar);

  const globalMenu = useMemo(() => authStore.getAccessableMenuItems([
    {
      title: lang.Lms.main,
      icon: IconDashboard,
      path: '',
    },
    {
      title: lang.CoreHr.myTeam,
      icon: IconWorkspace,
      path: 'workspace',
      permission: Pages.ClientWorkSpace,
    },
    {
      title: lang.CoreHr.company,
      icon: IconCompany,
      path: 'company',
      children: [
        {
          title: lang.CoreHr.newsAndPosts,
          icon: '',
          path: 'news',
          permission: Pages.Digest,
          disabled: !authStore.permission?.hasBlogEmployee,
        },
        {
          title: lang.Lms.calendarEvents,
          icon: '',
          circles: intentionInvitations,
          path: 'calendar-invitations',
          permission: Pages.LmsLearningPaths,
        },
        {
          title: lang.CoreHr.orgStructureShort,
          icon: '',
          path: 'org-structure',
          permission: Pages.ClientOrgStructure,
        },
        {
          title: lang.CoreHr.employees,
          icon: '',
          path: 'employees-infos',
          permission: Pages.ClientEmployee,
        },
        // {
        //   title: lang.CoreHr.vacationSchedule,
        //   icon: '',
        //   path: 'vacations',
        //   permission: Pages.ClientOrgStructure,
        // },
        {
          title: lang.CoreHr.appsAndServices,
          icon: '',
          path: 'bookmarks',
        },
        {
          title: lang.Lms.knowledgeBase,
          icon: '',
          path: 'posts-catalog',
          permission: Pages.Digest,
        },
      ],
    },

    {
      title: lang.Lms.onboarding, // Адаптация
      icon: IconAdaptation,
      path: 'adaptation',
      permission: Pages.LmsCourseConstructorControl,
      children: [
        {
          title: lang.Lms.adaptationPrograms,
          icon: '',
          path: 'programs',
          permission: Pages.LmsCourseConstructorControl,
        },
      ],
    },
    {
      title: lang.Lms.educationalCenter,
      icon: IconLearningCenter,
      path: 'learning-center',
      children: [
        // {
        //   title: lang.Lms.myCourses,
        //   icon: '',
        //   path: 'courses',
        //   circles: courses,
        // },
        // {
        //   title: 'ИПР',
        //   icon: '',
        //   path: 'personal-dev-plan',
        //   disabled: AppConsts.isProd,
        // },
        // {
        //   title: 'Назначение ИПР',
        //   icon: '',
        //   path: 'personal-assigned-dev-plan',
        //   disabled: AppConsts.isProd,
        // },
        {
          title: lang.Lms.meetingsPlan,
          icon: '',
          path: 'events-plan',
          circles: intentionGroups,
          permission: Pages.LmsGroupsControl,
        },
        {
          title: lang.Lms.groups, // TODO: rename to "learning groups"
          icon: '',
          path: 'groups',
          circles: groups,
          permission: Pages.LmsGroups,
        },
        {
          title: lang.Lms.reports,
          icon: '',
          path: 'reports',
          permission: Pages.LmsReports,
        },
      ],
    },
    {
      title: lang.Lms.myEducation,
      icon: IconCourse,
      path: 'education',
      children: [
        {
          title: lang.Lms.myCourses,
          icon: '',
          path: 'courses',
          circles: courses,
          permission: Pages.LmsLearningPaths,
        },
        // {
        //   title: 'ИПР',
        //   icon: '',
        //   path: 'personal-dev-plan',
        //   disabled: AppConsts.isProd,
        // },
        // {
        //   title: 'Назначение ИПР',
        //   icon: '',
        //   path: 'personal-assigned-dev-plan',
        //   disabled: AppConsts.isProd,
        // },
        // {
        //   title: lang.Lms.knowledgeBase,
        //   icon: '',
        //   path: 'knowledge-base',
        //   permission: Pages.PostEditor,
        // },
        {
          title: lang.Lms.selfEducation,
          icon: '',
          path: 'courses-for-reading',
          permission: Pages.LmsCourseAccesses,
        },
      ],
    },
    {
      title: lang.Lms.talentManagementAndEvaluation,
      icon: IconMedal,
      path: 'score-talents',
      permission: Pages.HcmUser,
      children: [
        {
          title: lang.Hcm.colleaugeAssessAndPersonal,
          icon: '',
          path: 'surveys',
          children: [
            {
              title: '',
              icon: '',
              path: 'select-person',
            },
          ],
        },
        {
          title: lang.Hcm.conductingAssessment,
          icon: '',
          path: 'scoring',
          permission: Pages.HcmAdmin,
          children: [
            {
              title: '',
              icon: '',
              path: 'evaluation',
            },
          ],
        },
      ],
    },
    {
      title: lang.CoreHr.workWithContent,
      icon: IconGrade,
      path: 'content',
      children: [
        {
          title: lang.CoreHr.files,
          icon: '',
          path: 'files',
          permission: Pages.LmsFileStorageWrite,
        },
        {
          title: lang.CoreHr.contentLibrary,
          icon: '',
          path: 'library',
          permission: [Pages.LmsCourseConstructorControl, Pages.PostEditor],
        },
        {
          title: lang.Lms.createCourseTestQuiz,
          icon: '',
          path: 'constructor',
          permission: Pages.LmsCourseConstructorControl,
        },
        {
          title: lang.CoreHr.createPost,
          icon: '',
          path: 'posts',
          permission: Pages.PostEditor,
          disabled: !authStore.permission?.hasBlogEmployee,
        },

        {
          title: lang.Lms.createLearningPath,
          icon: '',
          path: 'create-education-guide',
          permission: Pages.LmsCourseConstructorControl,
        },
        // {
        //   title: lang.CoreHr.createEvent,
        //   icon: '',
        //   circles: intentionGroups,
        //   path: 'meetings-plan',
        // },
        {
          title: lang.CoreHr.coAuthorship,
          icon: '',
          path: 'collaboration',
          permission: Pages.LmsCourseConstructorEditors,
        },
      ],
    },
    {
      title: lang.Lms.assessmentTools,
      icon: IconDirectory,
      path: 'directories',
      permission: Pages.HcmAdmin,
      children: [
        {
          title: lang.Hcm.jobProfiles,
          icon: '',
          path: 'jobs',
          // disabled: AppConsts.isProd,
        },
        {
          title: lang.Hcm.competencies,
          icon: '',
          path: 'competencies',
        },

        {
          title: lang.Hcm.evaluationMethods,
          icon: '',
          path: 'methods',
        },
        {
          title: lang.Hcm.materialsForEvaluation,
          icon: '',
          path: 'materials',
        },
        {
          title: lang.Hcm.materialsForDevPlan,
          icon: '',
          path: 'plan-materials',
          // disabled: AppConsts.isProd,
        },
      ],
    },
  ]), [authStore.permission?.forbiddenRoutes]);

  const adminMenu: Route[] | undefined | null = useMemo(() => authStore.getAccessableMenuItems([
    {
      title: lang.Lms.main,
      icon: IconDashboard,
      path: '/admin',
      permission: Pages.CoreHrAccess,
    },
    {
      title: lang.CoreHr.company,
      icon: IconCompany,
      path: 'business-units',
      permission: Pages.CoreHrAccess,
      children: [
        {
          title: lang.CoreHr.orgStructureShort,
          icon: '',
          path: 'org-structure',
          permission: Pages.CoreHrAccess,
        },
      ],
    },
    {
      title: lang.CoreHr.forms,
      icon: IconPolls,
      path: 'forms',
      permission: Pages.CoreHrAccess,
      circles: formCircles?.allCircleNotifications.total,
    },
    {
      title: lang.Lms.employees,
      icon: IconUsers,
      path: 'employees',
      permission: Pages.CoreHrAccess,
    },
    {
      title: lang.CoreHr.directory,
      icon: IconDirectory,
      path: 'directories',
      permission: Pages.CoreHrAccess,
      children: [
        {
          title: lang.Lms.import,
          icon: '',
          path: 'import',
        },
        {
          title: lang.CoreHr.companyTypes,
          icon: '',
          path: 'business-units-types',
        },
        {
          title: lang.CoreHr.divisionsTypes,
          icon: '',
          path: 'division-types',
        },
        // {
        //   title: lang.Lms.integrations,
        //   icon: '',
        //   path: 'integrations',
        //   disabled: true,
        // },
      ],
    },
    {
      title: lang.CoreHr.settings,
      icon: IconSettings,
      path: 'settings',
      permission: Pages.Administrator,
      children: [
        {
          title: lang.CoreHr.userAccounts,
          icon: IconUsers,
          path: 'accounts',
        },
        {
          title: lang.Lms.roles,
          icon: '',
          path: 'roles',
        },

        {
          title: lang.CoreHr.portalSettings,
          icon: '',
          path: 'portal',
        },
        {
          title: lang.CoreHr.authMethod,
          icon: '',
          path: 'auth-methods',
        },
        {
          title: lang.CoreHr.notifications,
          icon: '',
          path: 'notifications',
        },
      ],
    },

    {
      title: lang.CoreHr.files,
      icon: IconFiles,
      path: 'files',
      permission: Pages.CoreHrAccess,
    },
  ]), [authStore.permission?.forbiddenRoutes]);

  const routes = useMemo<Route[] | undefined | null>(() => (authStore.isAdmin ? adminMenu : globalMenu), [authStore.isAdmin, adminMenu, globalMenu]);

  const emptyMenu = useMemo(() => authStore.hasNoEmployeeCard && !authStore.isAdmin, [authStore.isAdmin, authStore.hasNoEmployeeCard]);

  useEffect(() => {
    if (authStore.locationBeforeManualLogout) {
      console.warn(`Redirect to ${authStore.locationBeforeManualLogout}`);

      navigate(authStore.locationBeforeManualLogout);

      authStore.setLocationBeforeManualLogout(null);
    }
  }, [authStore.locationBeforeManualLogout]);

  if (emptyMenu) {
    return <NavScaffold views={[]}><Outlet /></NavScaffold>;
  }

  return (
    <NavScaffold views={routes}>
      <Outlet />
    </NavScaffold>
  );
}

export default observer(Index);
