// Контроль доступов - идет с бекенда

enum Pages {
  AbpIdentityRoles = 'AbpIdentity.Roles',
  AbpIdentityUsers = 'AbpIdentity.Users',
  CoreHrBusinessUnits = 'CoreHr.BusinessUnits',
  CoreHrBusinessUnitTypes = 'CoreHr.BusinessUnitTypes',
  CoreHrDivisionTypes = 'CoreHr.DivisionTypes',
  CoreHrDivision = 'CoreHr.Division',
  CoreHrEmployees = 'CoreHr.Employees',
  CoreHrJobs = 'CoreHr.Jobs',
  CoreHrStaffUnits = 'CoreHr.StaffUnits',
  CoreHrBusinessUnitsTrees = 'CoreHr.BusinessUnitsTrees',

  CoreHrAccess = 'CoreHr.FullAdmin',
  //
  // LmsLmsEmployees = 'Lms.LmsEmployees',
  // LmsCourseEditors = 'Lms.CourseEditors',

  LmsEducation = 'Lms.Education',
  LmsLearningPaths = 'Lms.Education.LearningPathPassings',
  LmsCourseAccesses = 'Lms.Education.CourseAccesses',

  LmsGroups = 'Lms.LmsGroups',
  LmsGroupsControl = 'Lms.LmsGroups.Control',
  LmsGroupsManager = 'Lms.LmsGroups.Manager',

  LmsReports = 'Lms.Reports',

  LmsCourseConstructor = 'Lms.CourseConstructor',
  LmsCourseConstructorControl = 'Lms.CourseConstructor.Control',
  LmsCourseConstructorEditors = 'Lms.CourseConstructor.Editors',

  ClientDefault = 'Client.Default',
  ClientOrgStructure = 'Client.Default.OrgStructure',
  ClientEmployee = 'Client.Default.Employee',
  ClientWorkSpace = 'Client.Default.WorkSpace',

  LmsFileStorage = 'Lms.FileStorage',
  LmsFileStorageWrite = 'Lms.FileStorage.Write',
  LmsFileStorageRead = 'Lms.FileStorage.Read',

  Administrator = 'IsAdmin',
  PostEditor = 'Blogging.PostEditor',
  Digest = 'Blogging.Digest',
  BloggingReports = 'Blogging.Reports',

  HcmAdmin = 'Hcm.Admin',
  HcmUser = 'Hcm.User',
}

export default Pages;
