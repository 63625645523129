import { observer } from 'mobx-react';
import React, { Suspense } from 'react';
import Sidebar from '../Sidebar';
import AppBar from '../AppBar';
import Route from '../../models/Route';
import styles from './index.module.css';
import Loading from '../Elements/Loading';

interface Props {
  views: Route[] | undefined | null;
  children: React.ReactNode;
}

const NavScaffold = observer(({ views, children }: Props) => (
  <div className={styles.area}>
    <Sidebar views={views} />
    <div className={styles.content}>
      <AppBar />
      <Suspense fallback={<Loading />}>
        <div className={styles.body}>{children}</div>
      </Suspense>
    </div>
  </div>
));

export default NavScaffold;
