export const CoreHrLocaleKeys = (T: (e: string) => string) => ({
  companies: T('Companies_UI'),
  company: T('Company_UI'),
  employees: T('Employees_UI'),
  employee: T('Employee_UI'),
  directory: T('Directory_UI'),
  settings: T('Settings_UI'),
  support: T('Support_UI'),
  login: T('Login_UI'),
  installTraforyApp: T('InstallTraforyApp_UI'),
  learningIsEasy: T('LearningIsEasy_UI'),
  list: T('List_UI'),
  catalogue: T('Catalogue_UI'),
  orgStructure: T('OrgStructure_UI'),
  status: T('Status_UI'),
  loginLabel: T('LoginLabel_UI'),
  jobTitle: T('JobTitle_UI'),
  deparment: T('Deparment_UI'),
  workLocation: T('WorkLocation_UI'),
  account: T('Account_UI'),
  profile: T('Profile_UI'),
  password: T('Password_UI'),
  name: T('Name_UI'),
  surname: T('Surname_UI'),
  middleName: T('MiddleName_UI'),
  gender: T('Gender_UI'),
  birthday: T('Birthday_UI'),
  save: T('Save_UI'),
  cancel: T('Cancel_UI'),
  import: T('Import_UI'),
  users: T('Users_UI'),
  employeesCount: T('EmployeesCount_UI'),
  edit: T('Edit_UI'),
  delete: T('Delete_UI'),
  deleteData: T('DeleteData_UI'),
  addJob: T('AddJob_UI'),
  editJob: T('EditJob_UI'),
  add: T('Add_UI'),
  jobName: T('JobName_UI'),
  jobCode: T('JobCode_UI'),
  jobParent: T('JobParent_UI'),
  notifications: T('Notifications_UI'),
  languages: T('Languages_UI'),
  design: T('Design_UI'),
  designSlogan: T('DesignSlogan_UI'),
  languageSlogan: T('LanguageSlogan_UI'),
  notificationsSlogan: T('NotificationsSlogan_UI'),
  startWork: T('StartWork_UI'),
  helpCenter: T('HelpCenter_UI'),
  mostAskedQuestions: T('MostAskedQuestions_UI'),
  veryWelcome: T('VeryWelcome_UI'),
  watchVideoLearnPlatform: T('WatchVideoLearnPlatform_UI'),
  whatIsYourQuestion: T('WhatIsYourQuestion_UI'),
  enterQuestionInfoHere: T('EnterQuestionInfoHere_UI'),
  dragFilesToUpload: T('DragFilesToUpload_UI'),
  supportTermsCheckbox: T('SupportTermsCheckbox_UI'),
  send: T('Send_UI'),
  yourCompan: T('YourCompany_UI'),
  uploadFiles: T('UploadFiles_UI'),
  searchDocuments: T('SearchDocuments_UI'),
  title: T('Title_UI'),
  color: T('Color_UI'),
  newDivisionType: T('NewDivisionType_UI'),
  newBusinessUnitType: T('NewBusinessUnitType_UI'),
  addUser: T('AddUser_UI'),
  companyType: T('CompanyType_UI'),
  businessUnits: T('BusinessUnits_UI'),
  areYouSure: T('AreYouSure_UI'),
  businessUnitsTypes: T('BusinessUnitsTypes_UI'),
  divisionsTypes: T('DivisionsTypes_UI'),
  newBusinessUnit: T('NewBusinessUnit_UI'),
  address: T('Address_UI'),
  home: T('Home_UI'),
  businessUnit: T('BusinessUnit_UI'),
  divisionsType: T('DivisionsType_UI'),
  jobs: T('Jobs_UI'),
  subdivision: T('Subdivision_UI'),
  establishedUnit: T('EstablishedUnit_UI'),
  addEstablishedUnit: T('AddEstablishedUnit_UI'),
  logout: T('Logout_UI'),
  orgStructureShort: T('OrgStructureShort_UI'),
  paste: T('Paste_UI'),
  accessOnEdit: T('AccessOnEdit_UI'),
  accessOnPreview: T('AccessOnPreview_UI'),
  block: T('Block_UI'),
  subordination: T('Subordination_UI'),
  aboutUs: T('AboutUs_UI'),
  companyAddress: T('CompanyAddress_UI'),
  contacts: T('Contacts_UI'),
  companyVAT: T('CompanyVAT_UI'),
  companyWebsite: T('CompanyWebsite_UI'),
  email: T('Email_UI'),
  centralControl: T('CentralControl_UI'),
  main: T('Main_UI'),
  tree: T('Tree_UI'),
  basicInformation: T('BasicInformation_UI'),
  additionalInformation: T('AdditionalInformation_UI'),
  notProvided: T('NotProvided_UI'),
  chooseSupervisingCompany: T('ChooseSupervisingCompany_UI'),
  supervisingCompany: T('SupervisingCompany_UI'),
  country: T('Country_UI'),
  selectCountry: T('SelectCountry_UI'),
  region: T('Region_UI'),
  selectRegion: T('SelectRegion_UI'),
  city: T('City_UI'),
  selectCity: T('SelectCity_UI'),
  postcode: T('Postcode_UI'),
  officeRoom: T('OfficeRoom_UI'),
  contactPhone: T('ContactPhone_UI'),
  enterContactPhone: T('EnterContactPhone_UI'),
  enterTheLastNameFirstNameOfTheEmployee: T('EnterTheLastNameFirstNameOfTheEmployee_UI'),
  enterThePositionOfTheEmployee: T('EnterThePositionOfTheEmployee_UI'),
  enterCompanyWebsiteAddress: T('EnterCompanyWebsiteAddress_UI'),
  enterCompanyEmail: T('EnterCompanyEmail_UI'),
  aboutCompany: T('AboutCompany_UI'),
  registrationNumber: T('RegistrationNumber_UI'),
  businessUnitName: T('BusinessUnitName_UI'),
  enterTitle: T('EnterTitle_UI'),
  selectCompanyType: T('SelectCompanyType_UI'),
  enterCompanyVAT: T('EnterCompanyVAT_UI'),
  enterRegistrationNumber: T('EnterRegistrationNumber_UI'),
  littleAboutCompany: T('LittleAboutCompany_UI'),
  editingBusinessUnit: T('EditingBusinessUnit_UI'),
  addDivision: T('AddDivision_UI'),
  preview: T('Preview_UI'),
  personalDetails: T('PersonalDetails_UI'),
  licenceAgreement: T('LicenceAgreement_UI'),
  appManagement: T('AppManagement_UI'),
  progress: T('Progress_UI'),
  continue: T('Continue_UI'),
  myCourses: T('MyCourses_UI'),
  secondaryColor: T('SecondaryColor_UI'),
  primaryColor: T('PrimaryColor_UI'),
  reset: T('Reset_UI'),
  update: T('Update_UI'),
  course: T('Course_UI'),
  previewInSelectedColors: T('PreviewInSelectedColors_UI'),
  applyToAllModules: T('ApplyToAllModules_UI'),
  companyImport: T('CompanyImport_UI'),
  companyImportSubtitle: T('CompanyImportSubtitle_UI'),
  importInfoAboutCompany: T('ImportInfoAboutCompany_UI'),
  addDataAboutCompany: T('AddDataAboutCompany_UI'),
  importOrgStructure: T('ImportOrgStructure_UI'),
  addDivisionsDeparmentsContacts: T('AddDivisionsDeparmentsContacts_UI'),
  quicklyImportCompanyInfo: T('QuicklyImportCompanyInfo_UI'),
  downloadAndFillDataCompany: T('DownloadAndFillDataCompany_UI'),
  downloadReadyTemplateBelow: T('DownloadReadyTemplateBelow_UI'),
  uploadData: T('UploadData_UI'),
  upload: T('Upload_UI'),
  startImport: T('StartImport_UI'),
  dataImport: T('DataImport_UI'),
  downloadTemplate: T('DownloadTemplate_UI'),
  importUsers: T('ImportUsers_UI'),
  usersImportSubtitle: T('UsersImportSubtitle_UI'),
  importNewUsers: T('ImportNewUsers_UI'),
  addNewUsers: T('AddNewUsers_UI'),
  updateExistingUsers: T('UpdateExistingUsers_UI'),
  recommendedForLargeUpdate: T('RecommendedForLargeUpdate_UI'),
  quicklyImportUsersInfo: T('QuicklyImportUsersInfo_UI'),
  downloadAndFillDataUsers: T('DownloadAndFillDataUsers_UI'),
  importJobs: T('ImportJobs_UI'),
  jobsImportSubtitle: T('JobsImportSubtitle_UI'),
  importNewJobs: T('ImportNewJobs_UI'),
  addNewJobs: T('AddNewJobs_UI'),
  updateExistingJobs: T('UpdateExistingJobs_UI'),
  quicklyImportJobsInfo: T('QuicklyImportJobsInfo_UI'),
  downloadAndFillDataJobs: T('DownloadAndFillDataJobs_UI'),
  addCompany: T('AddCompany_UI'),
  companyName: T('CompanyName_UI'),
  ownTypeCanCreateInDirectory: T('OwnTypeCanCreateInDirectory_UI'),
  selectType: T('SelectType_UI'),
  editData: T('EditData_UI'),
  importOrgStructureInfo: T('ImportOrgStructureInfo_UI'),
  importJobsInfoAbout: T('ImportJobsInfoAbout_UI'),
  importUsersAbout: T('ImportUsersAbout_UI'),
  chooseCompany: T('ChooseCompany_UI'),
  selectCompanyInImport: T('SelectCompanyInImport_UI'),
  companyTypes: T('CompanyTypes_UI'),
  addCompanyType: T('AddCompanyType_UI'),
  typeName: T('TypeName_UI'),
  addDivisionType: T('AddDivisionType_UI'),
  selectDivision: T('SelectDivision_UI'),
  selectJob: T('SelectJob_UI'),
  NoEmployeesBirthInThisMonth: T('NoEmployeesBirthsInThisMonth_UI'),
  selectEmployeeUponRequirement: T('SelectEmployeeUponRequirement_UI'),
  firstDivisionEntry_UI: T('FirstDivisionEntry_UI'),
  firstJobsEntry_UI: T('FirstJobsEntry_UI'),
  enterCodeIfExists: T('EnterCodeIfExists_UI'),
  accessRight: T('AccessRight_UI'),
  notificationSettings: T('NotificationSettings_UI'),
  languageSettings: T('LanguageSettings_UI'),
  designSettings: T('DesignSettings_UI'),
  designPreview: T('DesignPreview_UI'),
  systemColors: T('SystemColors_UI'),
  editCompanyType: T('EditCompanyType_UI'),
  analytics: T('Analytics_UI'),
  files: T('Files_UI'),
  content: T('Content_UI'),
  selectJobsImportInfo: T('SelectJobsImportInfo_UI'),
  dataValidation: T('DataValidation_UI'),
  reupload: T('Reupload_UI'),
  integrations: T('Integrations_UI'),
  importDivisions: T('ImportDivisions_UI'),
  importDivisionsInfo: T('ImportDivisionsInfoAbout_UI'),
  importEmployees: T('ImportEmployees_UI'),
  importEmployeesInfoAbout: T('ImportEmployeesInfoAbout_UI'),
  hierarchy: T('Hierarchy_UI'),
  forms: T('Forms_UI'),
  data: T('Data_UI'),
  newInquiries: T('NewInquiries_UI'),
  totalInquiries: T('TotalInquiries_UI'),
  addForm: T('AddForm_UI'),
  formName: T('FormName_UI'),
  formType: T('FormType_UI'),
  inquiry: T('Inquiry_UI'),
  inquiryStatus: T('InquiryStatus_UI'),
  approve: T('Approve_UI'),
  reject: T('Reject_UI'),
  fillTheForm: T('FillTheForm_UI'),
  proceed: T('Proceed_UI'),
  consentToProcessing: T('ConsentToProcessing_UI'),
  personalData: T('PersonalData_UI'),
  completeSolution: T('CompleteSolution_UI'),
  inquiries: T('Inquiries_UI'),
  form: T('Form_UI'),
  details: T('Details_UI'),
  copyLink: T('CopyLink_UI'),
  myTariff: T('MyTarif_UI'),
  totalCompanies: T('TotalCompanies_UI'),
  totalEmployees: T('TotalEmployees_UI'),
  totalUsers: T('TotalUsers_UI'),
  inactiveUsers: T('InactiveUsers_UI'),
  undistributedEmployees: T('UndistributedEmployees_UI'),
  availableUsers: T('AvailableUsers_UI'),
  uploadedUsers: T('UploadedUsers_UI'),
  licenceDuration: T('LicenceDuration_UI'),
  size: T('Size_UI'),
  getLink: T('GetLink_UI'),
  totalInquries: T('TotalInquries_UI'),
  mainSlogan: T('MainSlogan_UI'),
  reenterPassword: T('ReenterPassword_UI'),
  applicationSent: T('ApplicationSent_UI'),
  applicationSentText: T('ApplicationSentText_UI'),
  userAccounts: T('UserAccounts_UI'),
  emailTemplates: T('EmailTemplates_UI'),
  addEmployeeCard: T('AddEmployeeCard_UI'),
  enterLastName: T('EnterLastName_UI'),
  enterFirstName: T('EnterFirstName_UI'),
  enterMiddleName: T('EnterMiddleName_UI'),
  enterEmail: T('EnterEmail_UI'),
  accessToLMS: T('AccessToLMS_UI'),
  accessToHCM: T('AccessToHCM_UI'),
  visitor: T('Visitor_UI'),
  createEmployeeCard: T('CreateEmployeeCard_UI'),
  userAccount: T('UserAccount_UI'),
  addRole: T('AddRole_UI'),
  notChosenRole: T('NotChosenRole_UI'),
  AddLinkedAccount: T('AddLinkedAccount_UI'),
  AddLinkedEmployee: T('AddLinkedEmployee_UI'),
  active: T('Active_UI'),
  lastVisit: T('LastVisit_UI'),
  createUserAccount: T('CreateUserAccount_UI'),
  addUserAccount: T('AddUserAccount_UI'),
  unlock: T('Unlock_UI'),
  addEmployee: T('AddEmployee_UI'),
  deleteUserAccount: T('DeleteUserAccount_UI'),
  deleteEmployeeCard: T('DeleteEmployeeCard_UI'),
  prePreviewAndErrorFix: T('PrePreviewAndErrorFix_UI'),
  fixAllErrorAndReuloadFile: T('FixAllErrorAndReuloadFile_UI'),
  jobInformation: T('JobInformation_UI'),
  userAccountEmail: T('UserAccountEmail_UI'),
  placeOfWork: T('PlaceOfWork_UI'),
  supervisor: T('Supervisor_UI'),
  aboutMe: T('AboutMe_UI'),
  tellAboutSelf: T('TellAboutSelf_UI'),
  myInterests: T('MyInterests_UI'),
  tellAboutSelfInfo: T('TellAboutSelfInfo_UI'),
  myInterestsInfo: T('MyInterestsInfo_UI'),
  howCanIHelp: T('HowCanIHelp_UI'),
  errorPleaseCheckTheInformationYouEnteredIsCorrected: T('ErrorPleaseCheckTheInformationYouEnteredIsCorrected_UI'),
  jobProfile: T('JobProfile_UI'),
  positionInformation: T('PositionInformation_UI'),
  userEmail: T('UserEmail_UI'),
  enterLogin: T('EnterLogin_UI'),
  changePassword: T('ChangePassword_UI'),
  passwordChanging: T('PasswordChanging_UI'),
  changePasswordConfirmed: T('ChangePasswordConfirmed_UI'),
  directSubordinats: T('DirectSubordinats_UI'),
  moveUp: T('MoveUp_UI'),
  checkEmail: T('CheckEmail_UI'),
  adminPanel: T('AdminPanel_UI'),
  clientApp: T('ClientApp_UI'),
  packageName: T('PackageName_UI'),
  perpetual: T('Perpetual_UI'),
  unlimited: T('Unlimited_UI'),
  emailIncorrectError: T('EmailIncorrectError_UI'),
  general: T('General_UI'),
  generalInfoOnHowEverythingWorks: T('GeneralInfoOnHowEverythingWorks_UI'),
  manualForTrafory: T('ManualForTrafory_UI'),
  systemEntrance: T('SystemEntrance_UI'),
  forgotPasswordCantLogin: T('ForgotPasswordCantLogin_UI'),
  instructionForUsers: T('InstructionForUsers_UI'),
  instructionForUsersWithDiffAccess: T('InstructionForUsersWithDiffAccess_UI'),
  manualAdmin: T('ManualAdmin_UI'),
  manualManager: T('ManualManager_UI'),
  manualemployee: T('ManualEmployee_UI'),
  appInsallation: T('AppInsallation_UI'),
  cantInstallApp: T('CantInstallApp_UI'),
  mistakes: T('Mistakes_UI'),
  areYouFacingProblem: T('AreYouFacingProblem_UI'),
  accessControl: T('AccessControl_UI'),
  howToGrantAccess: T('HowToGrantAccess_UI'),
  workingWithUsers: T('WorkingWithUsers_UI'),
  allActionsWithUsers: T('AllActionsWithUsers_UI'),
  verification: T('Verification_UI'),
  sendingVerifyEmails: T('SendingVerifyEmails_UI'),
  ifYouHaveQuestionYouCanContact: T('IfYouHaveQuestionYouCanContact_UI'),
  supportCenter: T('SupportCenter_UI'),
  youHaveNotAccessToFunctionality: T('YouHaveNotAccessToFunctionality_UI'),
  systemEntryInfo: T('SystemEntryInfo_UI'),
  enterCode: T('EnterCode_UI'),
  sendEmailForPasswordChange: T('SendEmailForPasswordChange_UI'),
  replaceLogo: T('ReplaceLogo_UI'),
  linksColor: T('LinksColor_UI'),
  logoSmall: T('LogoSmall_UI'),
  logoBig: T('LogoBig_UI'),
  logoFull: T('LogoFull_UI'),
  font: T('Font_UI'),
  homePageLink: T('HomePageLink_UI'),
  authMethod: T('AuthMethod_UI'),
  addMethod: T('AddMethod_UI'),
  editMethod: T('EditMethod_UI'),
  enterData: T('EnterData_UI'),
  enterCompanyName: T('EnterCompanyName_UI'),
  usePasswordByDefault: T('UsePasswordByDefault_UI'),
  passwordLifetime: T('PasswordLifetime_UI'),
  passwordByDefault: T('PasswordByDefault_UI'),
  enterDaysCount: T('EnterDaysCount_UI'),
  days: T('Days_UI'),
  hours: T('Hours_UI'),
  userEmailChangeConfirm: T('UserEmailChangeConfirm_UI'),
  companyLabel: T('CompanyLabel_UI'),
  link: T('Link_UI'),
  lockedUntil: T('LockedUntil_UI'),
  linkExpirationDate: T('LinkExpirationDate_UI'),
  enterHoursCount: T('EnterHoursCount_UI'),
  birthdays: T('Birthdays_UI'),
  phone: T('Phone_UI'),
  employmentDate: T('EmploymentDate_UI'),
  vacationSchedule: T('VacationSchedule_UI'),
  all: T('All_UI'),
  period: T('Period_UI'),
  amountOfDays: T('AmountOfDays_UI'),
  vacationDate: T('VacationDate_UI'),
  noAllSelectedItemsHaveAccount: T('NoAllSelectedItemsHaveAccount_UI'),
  successful: T('Successful_UI'),
  myEmployees: T('MyEmployees_UI'),
  qualification: T('Qualification_UI'),
  workspace: T('Workspace_UI'),
  theEmployeeDoesNotHaveCoursesToComplite: T('TheEmployeeDoesNotHaveCoursesToComplite_UI'),
  numberOfPeopleInIheDepartment: T('NumberOfPeopleInIheDepartment_UI'),
  myTeam: T('MyTeam_UI'),
  newsAndPosts: T('NewsAndPosts_UI'),
  createPost: T('CreatePost_UI'),
  contentLibrary: T('ContentLibrary_UI'),
  appsAndServices: T('AppsAndServices_UI'),
  workWithContent: T('WorkWithContent_UI'),
  createEvent: T('CreateEvent_UI'),
  coAuthorship: T('CoAuthorship_UI'),
  loginPageTenantTooltip: T('LoginPageTenantTooltip_UI'),
  loginPageUsernameTooltip: T('LoginPageUsernameTooltip_UI'),
  loginPagePasswordTooltip: T('LoginPagePasswordTooltip_UI'),
  descriptionOfHowToUse: T('DescriptionOfHowToUse_UI'),
  instructionsForUsingTrafory: T('InstructionsForUsingTrafory_UI'),
  notFoundPageText: T('NotFoundPageText_UI'),
  forbiddenEmailDomain: T('ForbiddenDomain_UI'),
  toInsertPicture: T('ToInsertPicture_UI'),
  portalSettings: T('PortalSettings_UI'),
  traforiCertificates: T('TraforiCertificates_UI'),
  otherCertificates: T('OthreCertificates_UI'),
  linkToPersonalDataProcessingPolicy: T('LinkToPersonalDataProcessingPolicy_UI'),
  moveDown: T('MoveDown_UI'),
  citizenship: T('Citizenship_UI'),
  personalPhone: T('PersonalPhone_UI'),
  typeOfTraining: T('TypeOfTraining_UI'),
  educationalInstitution: T('EducationalInstitution_UI'),
  employeeNumber: T('EmployeeNumber_UI'),
  typeOfEmployment: T('TypeOfEmployment_UI'),
  typeOfObligatedPerson: T('TypeOfObligatedPerson_UI'),
  internshipStart: T('InternshipStart_UI'),
  internshipEnd: T('InternshipEnd_UI'),
  relatives: T('Relatives_UI'),
  familyMembers: T('FamilyMembers_UI'),
  text: T('Text_UI'),
  document: T('Document_UI'),
  maximumNumberOfClicksReached: T('MaximumNumberOfClicksReached_UI'),
  familyStatus: T('FamilyStatus_UI'),
  internshipType: T('InternshipType_UI'),
  obligatedPersonFrom: T('ObligatedPersonFrom_UI'),
  obligatedPersonTo: T('ObligatedPersonTo_UI'),
  suspendFromWork: T('SuspendFromWork_UI'),
  allowToWork: T('AllowToWork_UI'),
  suspended: T('Suspended_UI'),
  identificationNumber: T('IdentificationNumber_UI'),
  dateOfSuspensionFromWork: T('DateOfSuspensionFromWork_UI'),
  suspendedFrom: T('SuspendedFrom_UI'),
  addLinkedAccountForSuspendedEmployee: T('AddLinkedAccountForSuspendedEmployee_UI'),
  logo: T('Logo_UI'),
  links: T('Links_UI'),
  useCustomSmsProvider: T('UseCustomSmsProvider_UI'),
  isPhoneNeededConfirm: T('IsPhoneNeededConfirm_UI'),
  smsCode: T('SmsCode_UI'),
  youDontHaveAccess: T('YouDontHaveAccess_UI'),
  newTextEditor: T('NewTextEditor_UI'),
  selectWhoParticipate: T('SelectWhoParticipate_UI'),
});
