import { observer } from 'mobx-react';
import { Button, Form, Input, notification } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocale } from '../../App';
import styles from './index.module.css';
import http from '../../services/httpService';
import PhonesView from '../../components/Phones';
import Language from '../components/Language';
import SizedBox from '../../components/Elements/SizedBox';
import { validatePassword } from '../../utils/passwordUtils';
import PasswordValidator from '../../components/Elements/PasswordValidator';
import { getBase64Src } from '../../utils/utils';
import { appSettingsStore } from '../../stores/traforySettingStore';
import loginStyles from '../Login/index.module.css';

interface ICreatePasswordPageFormState {
  userId: string,
  previousPassword: string,
  password: string,
  repeatPassword: string,
  validationSms: string,
}

interface Output {
  tenant: string;
}

const Index = observer(() => {
  const { lang } = useLocale();

  const [searchParams] = useSearchParams();

  const [isLoading, setLoading] = useState(false);

  const [dto, setDto] = useState<ICreatePasswordPageFormState>({
    userId: '',
    previousPassword: '',
    password: '',
    repeatPassword: '',
    validationSms: '',
  });

  const nav = useNavigate();

  const tenantId = searchParams.get('tenantId');

  const shouldConfirmPhoneNextLogin = useMemo(() => searchParams.get('shouldConfirmPhoneNextLogin') === 'true', [searchParams]);

  const setParams = () => {
    const user = searchParams.get('userId');
    if (user) {
      setDto({ ...dto, userId: user, password: '', previousPassword: '', repeatPassword: '' });
    }
  };

  useEffect(() => {
    setParams();
  }, []);

  useEffect(() => {
    if (tenantId) {
      localStorage.setItem('tenantId', tenantId);
    }
    appSettingsStore.updateStyles();
  }, [tenantId]);

  const resetPassword = async () => {
    setLoading(true);
    try {
      const result = await http.post('/api/passwords-management/reset-password-with-previous', dto, {
        headers: {
          __tenant: tenantId,
        },
      });
      if (result.status === 200) {
        const data = result.data as Output;
        localStorage.setItem('tenancyName', data.tenant);
        notification.success({ message: lang.Lms.passwordUpdated });
        nav(`/?tenant=${data.tenant}`, { replace: true });
      }
    } finally {
      setLoading(false);
    }
  };

  const isValid = dto.repeatPassword === dto.password && validatePassword(dto.password);

  return (
    <div className={styles.area}>
      {appSettingsStore.data?.authCoverPicBase64 ? (
        <div
          style={{ backgroundImage: `url(${getBase64Src(appSettingsStore.data?.authCoverPicBase64)})` }}
          className={loginStyles.cover}
        />
      ) : <PhonesView />}
      <div className={styles.body}>
        <div className={styles.top}>
          <div style={{ paddingTop: 22 }}>{lang.Lms.enterTo}</div>
          <img
            src={getBase64Src(appSettingsStore.data?.logoFileBigBase64)}
            className={loginStyles.logo}
          />
        </div>
        <Form layout='vertical' className={styles.form} labelAlign='left' labelCol={{ span: 8 }}>
          <h2>{lang.Lms.oldPassword}</h2>
          <Input.Password
            value={dto.previousPassword}
            onChange={(e) => setDto({ ...dto, previousPassword: e.target.value })}
            type='password'
            size='large'
            placeholder={lang.Lms.enterPassword}
          />
          <SizedBox height={16} />
          <h2>{lang.Lms.newPassword}</h2>
          <Input.Password
            value={dto.password}
            onChange={(e) => setDto({ ...dto, password: e.target.value })}
            type='password'
            size='large'
            placeholder={lang.Lms.enterPassword}
            className={!validatePassword(dto.password) && dto.password.length > 1 ? 'input-password-error' : ''}
          />
          <SizedBox height={16} />
          <h2>{lang.Lms.repeatNewPassword}</h2>
          <Input.Password
            value={dto.repeatPassword}
            onChange={(e) => setDto({ ...dto, repeatPassword: e.target.value })}
            type='password'
            size='large'
            placeholder={lang.CoreHr.reenterPassword}
            disabled={!validatePassword(dto.password)}
            className={dto.repeatPassword === dto.password ? '' : styles.error}
          />
          <SizedBox height={24} />
          <PasswordValidator text={dto.password} />
          {
            shouldConfirmPhoneNextLogin ? (
              <>
                <h2 style={{ margin: '16px 0 8px 0' }}>{lang.CoreHr.smsCode}</h2>
                <Input
                  value={dto.validationSms}
                  onChange={(e) => setDto({ ...dto, validationSms: e.target.value })}
                  placeholder={lang.CoreHr.smsCode}
                  style={{ padding: '4px 11px' }}
                  type="number"
                />
              </>
            ) : null
          }

          <SizedBox height={24} />
          <div className={styles.proceed}>
            <Button disabled={!isValid || isLoading} onClick={resetPassword}>
              {lang.CoreHr.proceed}
            </Button>
          </div>
        </Form>
      </div>
      <Language />
    </div>
  );
});

export default Index;
