import { PagedResultDto } from '../../../../models/PagedResultDto';
import API from '../../../../services/Api';
import http from '../../../../services/httpService';
import { CreateEmployeeDto } from '../employeeService/dto';
import {
  CreateEmployeeFromUserDto,
  CreateUserAccountDto,
  CreateUserFromEmployeeDto,
  EmployeeProfileDto,
  GetPagedUserProfileListDto,
  UpdateUserAccountDto,
  UserProfileDto,
  UpdateUserRolesDto,
} from './dto';

class UserManagementService {
  public createALotEmployeesFromUsers = async (arrayOfUserIds: string[]) => {
    try {
      const result = await http.post(`${API.UsersManagement}create-many-employees-from-users`, arrayOfUserIds);

      return result;
    } catch (error) {
      console.error('UserManagementService.createALotEmployeesFromUsers', error);
    }
  };

  public async getUserProfilesList(e: GetPagedUserProfileListDto): Promise<PagedResultDto<UserProfileDto>> {
    const result = await http.get(`${API.UsersManagement}users-profiles`, { params: e });
    return result.data;
  }

  public async getEmployeeProfilesList(e: GetPagedUserProfileListDto): Promise<PagedResultDto<EmployeeProfileDto>> {
    const result = await http.get(`${API.UsersManagement}employees-profiles`, { params: e });
    return result.data;
  }

  // Create User Account with Employee ID selection
  public async createUserAccount(e: CreateUserAccountDto) {
    const result = await http.post(`${API.UsersManagement}create-account`, e);
    return result.status === 200;
  }

  public async updateUserAccount(e?: UpdateUserAccountDto, id?: string): Promise<UserProfileDto> {
    const result = await http.put(`${API.UsersManagement}user/${id}`, e);
    return result.data;
  }

  public async getUserAccount(id?: string): Promise<UserProfileDto> {
    const result = await http.get(`${API.UsersManagement}user/${id}`);
    return result.data;
  }

  public async createEmployeeAccount(e: CreateEmployeeDto) {
    const result = await http.post(API.CoreHR.Employee, e);
    return result.status === 200;
  }

  public async deleteEmployeeAccount(id: string) {
    const result = await http.delete(`${API.UsersManagement}employee/${id}`);
    return result.status === 200;
  }

  public async createUserFromEmployee(e: CreateUserFromEmployeeDto) {
    const result = await http.post(`${API.UsersManagement}create-user-from-employee`, e);
    return result.status === 200;
  }

  public async createEmployeeFromUser(e: CreateEmployeeFromUserDto) {
    const result = await http.post(`${API.UsersManagement}create-employee-from-user`, e);
    return result.status === 200;
  }

  public async deleteEmployeeCard(id: string): Promise<boolean> {
    const result = await http.delete(`/api/users-management/employee/${id}`);
    return result.data.isExisted;
  }

  public async deleteUserCard(id: string): Promise<boolean> {
    const result = await http.delete(`/api/users-management/user/${id}`);
    return result.data.isExisted;
  }

  public async updateUserRoles(e: UpdateUserRolesDto, id?: string) {
    const result = await http.put(`/api/users-management/${id}/roles`, e);
    return result.status === 200;
  }
}

export default new UserManagementService();
