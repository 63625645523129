import { useState, useEffect } from 'react';
import service from './index';
import { GetCircleNotificationListDto } from './dto';
import { CircleNotificationsSummary } from '../../models/CircleNotificationsSummary';

interface Props {
  data: GetCircleNotificationListDto;
}

// Hook
function useGetCirclesList({ data }: Props) {
  const [circles, setCircles] = useState<CircleNotificationsSummary[]>();

  useEffect(() => {
    const getData = async () => {
      const result = await service.getList(data);
      setCircles(result);
    };
    getData();
    return () => setCircles(undefined);
  }, []);
  return circles;
}

export default useGetCirclesList;
