// import { Modal } from 'antd';
import axios, { type AxiosInstance } from 'axios';
import AppConsts from '../libs/AppConsts';
import Lang from '../utils/lang/lang';
import showAlertDialog from '../utils/showAlertDialog';
import { AuthStoreIns } from '../context/authContextType';
import { getTranslationOutsideReact } from '../utils/lang/getTranslationOutsideReact';
import { history } from '../App';

const http: AxiosInstance & {
  cancelRequest?: () => void
} = axios.create({
  baseURL: AppConsts.server,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Accept-Language': Lang.locale,
  },
});

// Обработчик ошибок
http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 'ERR_CANCELED') {
      return error;
    }

    switch (error?.response?.status) {
      case 401:
        AuthStoreIns.logout();
        break;
      case 403:
        history.replace('/');

        showAlertDialog({
          title: getTranslationOutsideReact.Lms('Attention_UI'),
          content: 'TODO:TRANSALTE (Попытка получения контента с ограниченным доступом)',
        });
        break;
      default:
        showAlertDialog({
          content: error.response.data.error?.message,
          title: error.response.data?.error?.data?.Title?.value,
        });
    }

    return Promise.reject(error);
  },
);

// Request interceptors for API calls
http.interceptors.request.use(
  async (config) => {
    const tenancyName = localStorage.getItem('tenancyName');
    const tenantId = localStorage.getItem('tenantId');
    config.headers.Authorization = localStorage.getItem('token');
    config.headers['Accept-Language'] = Lang.locale;

    if (tenancyName || tenantId) {
      config.headers.__tenant = tenantId || tenancyName;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default http;
