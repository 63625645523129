import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';
import { Localization } from '../models/AppConfig';
import configService from '../services/ConfigService';
import Lang from '../utils/lang/lang';

class LangStore {
  localization?: Localization;

  isLoading = false;

  get localeData() {
    return new Lang(this.localization);
  }

  async getLocale() {
    this.isLoading = true;

    try {
      this.localization = await configService.getLocalization();
    } finally {
      this.isLoading = false;
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export default LangStore;

const LanguageStoreContext = createContext({ langStore: new LangStore() });

export const useLanguageStore = () => useContext(LanguageStoreContext);
