// Отображение кружечков (огоньки) нотификаций

enum CircleNotificationNodeType {
  LessonPassing = 1,
  CourseSkeleton = 2,
  Group = 3,
  Question = 4,
  LessonTable = 5,
  CoursePassing = 6,
  LearningPathPassing = 7,
  MenuGroups = 8,
  Comment = 9,
  Form = 10,
  FormResponse = 11,
  CuratorTable = 12,
  LessonSkeleton = 13,
  MenuMyCourses = 15,
  MenuGroupControl = 16,
  MenuGroupCuratorRoom = 17,
  MenuMyCoursesSummary = 18,
  MenuForms = 19,
  MenuMyCoursesCuratorChat = 20,
  MenuMyCoursesQuestions = 21,
  GroupReviewLessonWorkWithStudents = 22,
  GroupReviewLessonWorkWithStudentsQuestions = 23,
  MenuIntentionGroups = 24,
  IntentionGroup = 25,
  Invitation = 26,
  StudentInvitationCalendar = 27,
}

export default CircleNotificationNodeType;
