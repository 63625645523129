import React, { useEffect, type FC } from 'react';
import styles from './index.module.css';
import { getBase64Src } from '../../utils/utils';
import { appSettingsStore } from '../../stores/traforySettingStore';
import { useLocale } from '../../App';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface INotFound {
}

const NotFound: FC<INotFound> = () => {
  const { lang } = useLocale();

  const { data } = appSettingsStore;

  useEffect(() => {
    console.warn(`
      Route not found ${window.location}
    `);
  }, []);

  return (
    <div className={styles.notFound__wrapper}>
      <div>
        {
          data?.logoFileSBFullBase64 ? (
            <img
              src={getBase64Src(data?.logoFileSBFullBase64)}
            />
          ) : null
        }
        <h2>
          Page Not Found
        </h2>
        <p>{lang.CoreHr.notFoundPageText}</p>
      </div>
    </div>
  );
};

export default NotFound;
