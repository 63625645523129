import { makeAutoObservable } from 'mobx';
import SidebarState from '../enum/SidebarState';

export default class SidebarStore {
  state = SidebarState.open;

  get isExpanded() {
    return this.state === SidebarState.open;
  }

  // eslint-disable-next-line
  get width() {
    switch (this.state) {
      case SidebarState.open:
        return 212;
      case SidebarState.half:
        return 80;
      case SidebarState.hidden:
        return 0;
    }
  }

  toggle() {
    if (this.state === SidebarState.open) {
      this.state = SidebarState.half;
    } else {
      this.state = SidebarState.open;
    }
  }

  setOpen() {
    this.state = SidebarState.open;
  }

  setHalf() {
    this.state = SidebarState.half;
  }

  setHidden() {
    this.state = SidebarState.hidden;
  }

  constructor() {
    makeAutoObservable(this);
  }
}
