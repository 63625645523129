import { Modal, notification } from 'antd';
import { RouteObject } from 'react-router-dom';
import { makeAutoObservable } from 'mobx';
import ruRU from 'antd/locale/ru_RU';
import enUS from 'antd/locale/en_US';
import { AppConfig, Localization } from '../models/AppConfig';
import service from '../services/AuthService';
import {
  CheckTenantDto,
  LoginDto,
  TokenDto,
} from '../services/AuthService/dto';
import { ILicenseRoutesDTO, UserPermission } from '../models/UserPermission';
import Pages from '../enum/Pages';
import Lang from '../utils/lang/lang';
import Route from '../models/Route';

export const authErrorDescriptions = {
  incorrect_username_or_password: 'Invalid username or password!',
  attempts_limit:
    'The user account has been locked out due to invalid login attempts. Please wait a while and try again.',
};

type AccessableContentReturnType = Route | RouteObject;

export default class AuthStore {
  constructor() {
    makeAutoObservable(this);
  }

  userData: {
    userName: string,
    id: string,
    email: string,
  } = {
      userName: '',
      id: '',
      email: '',
    };

  tenant?: CheckTenantDto;

  app?: AppConfig;

  localization?: Localization;

  isGlobalLoading = false;

  isLoading = false;

  permission?: UserPermission;

  authToken: TokenDto | null = null;

  locale = ruRU;

  isAdmin = localStorage.getItem('isAdmin') === 'true';

  locationBeforeManualLogout: string | null = null;

  setLocationBeforeManualLogout = (location: string | null) => {
    this.locationBeforeManualLogout = location;
  };

  getAccessableMenuItems = (initialRoutes: Route[]): Route[] | undefined | null => {
    if (!this.permission?.forbiddenRoutes || this.permission?.forbiddenRoutes.children.length === 0) {
      return initialRoutes;
    }

    // To add parent item, that required for algorithm
    const refactoredInitialMenuItems = [{ path: '', children: initialRoutes }];

    const extractedMenuItems = this.getAccessableContent([this.permission?.forbiddenRoutes], refactoredInitialMenuItems);

    // To remove parent item, that required for algorithm
    const result = extractedMenuItems?.[0].children as Route[];

    return result;
  };

  getAccessableRoutes = (initialRoutes: RouteObject[]) => {
    if (!this.permission?.forbiddenRoutes || this.permission?.forbiddenRoutes.children.length === 0) {
      return initialRoutes;
    }

    return this.getAccessableContent([this.permission?.forbiddenRoutes], initialRoutes);
  };

  getAccessableContent = (providedApplier: ILicenseRoutesDTO[], initialObj?: Route[] | RouteObject[]): AccessableContentReturnType[] | null | undefined => {
    if (!providedApplier || providedApplier.length === 0 || !initialObj || initialObj.length === 0) {
      return undefined;
    }

    // any required, coz of "This expression is not callable." TS bug.
    return (initialObj as any[]).reduce((acc, init) => {
      const target = providedApplier.find(({ path: applierPath }) => init.path?.includes(applierPath));

      if (!target) return [...acc, init];

      if (!target.children || target.children.length === 0) return acc;

      const children = this.getAccessableContent(target.children, init.children);

      return [...acc, { ...init, children }];
    }, [] as AccessableContentReturnType[]);
  };

  setLocale(locale: string) {
    if (locale === 'ru') {
      this.locale = ruRU;
    } else {
      this.locale = enUS;
    }
  }

  setIsAdmin = (value: boolean) => {
    this.isAdmin = value;
    localStorage.setItem('isAdmin', `${value}`);
  };

  get isRussian() {
    return this.app?.localization.currentCulture.cultureName === 'ru';
  }

  get canAccessCoreHr() {
    if (
      this.permission?.permissions.find((e) => e.name === Pages.CoreHrAccess)
        ?.isGranted
    ) {
      return true;
    }

    if (
      this.permission?.permissions.find((e) => e.name === Pages.Administrator)
        ?.isGranted
    ) {
      return true;
    }

    return false;
  }

  get hasNoEmployeeCard() {
    return this.permission?.hasEmployee === false;
  }

  get user() {
    return this.app?.currentUser;
  }

  get currentLanguage() {
    return this.app?.localization.currentCulture.cultureName;
  }

  get isLogged() {
    return this.app?.currentUser.isAuthenticated;
  }

  async getConfig() {
    this.app = await service.getAppConfig();

    localStorage.setItem(
      'Locale',
      this.app.localization.currentCulture.cultureName,
    );

    this.setLocale(this.app.localization.currentCulture.cultureName);
  }

  async getUserPermissions() {
    this.permission = await service.getUserPermissions();
    if (this.isAdmin && !this.canAccessCoreHr) {
      this.isAdmin = false;
    }
  }

  verifyPermission = (permissionToCheck: string) => this.permission?.permissions.find((permissionFromBackendList) => permissionFromBackendList.name === permissionToCheck)?.isGranted;

  isPermissionUnlocked = (permissions?: string[] | string, requiresAll = false) => {
    if (!permissions) {
      return true;
    }

    if (Array.isArray(permissions)) {
      return permissions[requiresAll ? 'every' : 'some']((p) => this.verifyPermission(p));
    }

    if (this.verifyPermission(permissions)) {
      return true;
    }

    return false;
  };

  async checkTenant(e: string, lang: Lang) {
    this.isLoading = true;
    try {
      const result = await service.checkTenant(e);
      if (!result.success) {
        Modal.error({ title: lang.Lms.companyNotFound });
      }
      this.tenant = result;
    } finally {
      this.isLoading = false;
    }
  }

  async checkTenantById(e: string) {
    const result = await service.checkTenantById(e);
    // TODO: rewrite somehow this sh*t

    // if (!result.success) {
    //   Modal.error({ title: Lang.translations.Lms.texts.CompanyNotFound_UI });
    // }
    this.tenant = result;
  }

  async getAuthToken(data: LoginDto) {
    try {
      this.authToken = await service.getAuthToken(data);
    } catch (err: any) {
      notification.error({
        message:
          err.response.data.error_description
          || err.response.data?.error?.message,
      });
    }
  }

  async login(tenantName: string, e: LoginDto, lang: Lang) {
    try {
      this.isLoading = true;
      const tenantId = await service.checkTenant(tenantName);
      const result = await service.getAuthToken(e, tenantId.tenantId);
      localStorage.setItem('token', `Bearer ${result.access_token}`);
      await this.getConfig();
    } catch (err: any) {
      const { data } = err.response;

      let message = data.error_description || data?.error?.message;

      switch (data.error_description) {
        case authErrorDescriptions.incorrect_username_or_password:
          message = lang.Lms.invalidUsernameOrPassword;
          break;
        case authErrorDescriptions.attempts_limit:
          message = lang.Lms.userAccountTemporallyBlocked;
          notification.error({ message });
          break;
      }
    } finally {
      this.isLoading = false;
    }
  }

  async logout() {
    service.logout();

    this.tenant = undefined;

    if (this.app?.currentUser.isAuthenticated) {
      this.app.currentUser.isAuthenticated = false;
    }
  }
}

export const authStore = new AuthStore();
