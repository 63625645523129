import {
  Button, Checkbox, Form, Input, Modal,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { encode } from 'js-base64';
import styles from './index.module.css';
import PhonesBar from '../../components/Phones';
import { useLocale } from '../../App';
import Loading from '../../components/Elements/Loading';
import Language from '../components/Language';
import { GetDynamicFormOutput } from './service/dto';
import loginStyles from '../Login/index.module.css';
import { authStore } from '../../stores/authStore';
import { appSettingsStore } from '../../stores/traforySettingStore';
import { getBase64Src } from '../../utils/utils';

import service from './service';

export default function Index() {
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(true);

  const nav = useNavigate();

  const tenantName = searchParams.get('tenantName') ?? '';
  const tenantId = localStorage.getItem('tenantId');

  const formId = searchParams.get('formId') ?? '';

  const [isAccepted, setAccepted] = useState(false);

  const [form, setForm] = useState<GetDynamicFormOutput>();

  const { lang } = useLocale();

  const confirm = async () => {
    const res = encode(JSON.stringify(form?.responseTemplate));

    const result = await service.apply({ formType: form?.type, response: res }, tenantId || tenantName);
    if (result) {
      Modal.info({
        title: lang.CoreHr.applicationSent,
        content: lang.CoreHr.applicationSentText,
        closable: false,
        onOk: () => nav(`/?tenant=${tenantName}`),
        cancelButtonProps: { style: { display: 'none' } },
      });
    }
  };

  useEffect(() => {
    const setTenantStyles = async () => {
      if (tenantName) {
        await authStore.checkTenant(tenantName, lang);
        if (authStore.tenant?.success) {
          localStorage.setItem('tenancyName', tenantName);
          localStorage.setItem('tenantId', authStore.tenant.tenantId);
          appSettingsStore.updateStyles();
        }
      }
    };

    setTenantStyles();
  }, [tenantName]);

  useEffect(() => {
    const setData = async () => {
      if (formId) {
        try {
          setLoading(true);
          const result = await service.read(formId, tenantId || tenantName);
          setForm(result);
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
    };
    setData();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.cover}>
      {appSettingsStore.data?.authCoverPicBase64 ? (
        <div
          style={{ backgroundImage: `url(${getBase64Src(appSettingsStore.data?.authCoverPicBase64)})` }}
          className={loginStyles.cover}
        />
      ) : <PhonesBar />}
      <div className={styles.body}>
        <div className={loginStyles.header} style={{ margin: 40, marginBottom: 12 }}>
          <p>{lang.Lms.enterTo}</p>
          <img
            src={getBase64Src(appSettingsStore.data?.logoFileBigBase64)}
            className={loginStyles.logo}
            alt='logo'
          />
        </div>
        <h5>{lang.CoreHr.fillTheForm}</h5>
        <h6>{tenantName}</h6>
        <Form layout="vertical" className={styles.form} labelAlign="left" labelCol={{ span: 8 }}>
          {form?.responseTemplate.fields?.map((item, idx) => (
            <Form.Item
              style={{ padding: 0, border: 'none' }}
              hidden={item.isHidden}
              key={item.key}
              label={(
                <div className={styles.label}>
                  {item.label}
                  {item.isRequired && '*'}
                </div>
              )}
            >
              <Input
                placeholder={item.key}
                value={item.value}
                onChange={(e) => {
                  const arr = form.responseTemplate.fields ?? [];
                  arr[idx].value = e.target.value;
                  setForm({ ...form, responseTemplate: { ...form.responseTemplate, fields: arr } });
                }}
              />
            </Form.Item>
          ))}
          <Form.Item style={{ backgroundColor: 'transparent', border: 'none' }}>
            <Checkbox checked={isAccepted} onChange={(e) => setAccepted(e.target.checked)}>
              <div className={styles.grant}>
                {lang.CoreHr.consentToProcessing}
                {' '}
                <a href="https://unidev.by/docs/" rel="noreferrer" target="_blank">
                  {lang.CoreHr.personalData}
                </a>
              </div>
            </Checkbox>
          </Form.Item>
          <div className={styles.proceed}>
            <Button type="primary" disabled={!isAccepted} onClick={confirm}>
              {lang.CoreHr.proceed}
            </Button>
          </div>
        </Form>
      </div>
      <Language />
    </div>
  );
}
