import { createContext } from 'react';
import NotificationStore from '../stores/notificationStore';

export type NotificationStoreContextType = {
  notificationStore: NotificationStore;
};

export const NotificationStoreContext = createContext<NotificationStoreContextType>({
  notificationStore: new NotificationStore(),
});
