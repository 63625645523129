import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';
import Styles from './styles';
import Lang from './lang/lang';

type DateType = 'time' | 'dateLabel' | 'dateShort' | 'dateTime' | 'custom';

const signatures: any = {
  R0lGODdh: 'image/gif',
  R0lGODlh: 'image/gif',
  iVBORw0KGgo: 'image/png',
  UklGRh: 'image/webp',
  '/9j/': 'image/jpg',
};

class Utils {
  static alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  static pallete = [Styles.ocean, Styles.orange, Styles.green, Styles.purple, Styles.blue, Styles.yellow];

  static setStyles(primary: string, secondary: string, link?: string, defaultFont?: string) {
    const root = document.documentElement;

    root.style.setProperty('--primary', primary);
    root.style.setProperty('--primary_5', primary + 10);
    root.style.setProperty('--primary_10', primary + 20);
    root.style.setProperty('--primary_20', primary + 20);
    root.style.setProperty('--primary_50', primary + 50);
    root.style.setProperty('--secondary', secondary);
    root.style.setProperty('--secondary_10', secondary + 20);
    root.style.setProperty('--secondary_20', secondary + 40);

    root.style.setProperty('--secondary_5', secondary + 10);
    root.style.setProperty('--secondary_50', secondary + 50);
    root.style.setProperty('--secondary_50', secondary + 80);
    root.style.setProperty('--link', link || primary);
    root.style.setProperty('--default-font', defaultFont || 'Ubuntu');
  }

  static array(count: number) {
    return Array.from(Array(count).keys());
  }

  static capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  static isEven(i: number) {
    if (i % 2 === 0) {
      return true;
    }
    return false;
  }

  static getDate(type: DateType, e?: Date, pattern?: string, isLocalDate = true) {
    const date = isLocalDate ? dayjs(e).locale(Lang.locale) : dayjs.utc(e);
    let res = '';

    if (!e) {
      return ' - ';
    }

    switch (type) {
      case 'dateShort':
        res = date.format('YYYY-MM-DD');
        break;
      case 'dateTime':
        res = date.format('MMM D, YYYY HH:mm');
        break;

      case 'dateLabel':
        res = date.format('MMM D, YYYY');
        break;
      case 'time':
        res = date.format('HH:mm');
        break;
      case 'custom':
        res = date.format(pattern);
        break;
    }
    return this.capitalize(res);
  }

  static getDatesRange(type: DateType, dateA?: Date, dateB?: Date, placeholder?: string) {
    if (placeholder) {
      if (!dateA || !dateB) {
        return placeholder;
      }
    }
    return [this.getDate(type, dateA, undefined, false), this.getDate(type, dateB, undefined, false)].join(' - ');
  }

  static readingTime(e?: string) {
    const text = e ?? '';
    const wpm = 225;
    const words = text.trim().split(/\s+/).length;
    const time = Math.ceil(words / wpm);
    return time;
  }

  static openInNewTab(url: string) {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  static shuffleArray<T>(array: T[]): T[] {
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    const deepCopy = JSON.parse(JSON.stringify(array)) as typeof array;
    return deepCopy;
  }

  static removeHtmlTags(value?: string) {
    if (!value) {
      return '';
    }
    const stripedHtml = value.replace(/<[^>]+>/g, '');

    const textBody = stripedHtml.replace(/(<([^>]+)>)/gi, '').replace(/nbsp;/g, '');

    return textBody;
  }

  static shiftArrayItem<T>(list: T[], oldIndex: number, newIndex: number, up: boolean) {
    const data = list;
    if (up) {
      if (newIndex <= list.length - 1) {
        data.splice(newIndex, 0, data.splice(oldIndex, 1)[0]);
      }
    } else if (newIndex >= 0) {
      data.splice(newIndex, 0, data.splice(oldIndex, 1)[0]);
    }
    return data;
  }

  static delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  static loadScript(url: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;

    document.body.appendChild(script);
  }

  static downloadBase64FileWithoutMimeType = (base64Data: string, filename: string) => {
    // Decode the Base64 string
    const byteCharacters = atob(base64Data);

    // Create a Uint8Array from the decoded string
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob object from the byte array with a generic MIME type
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });

    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename;

    // Append the link to the DOM and trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Remove the link from the DOM after the download is complete
    setTimeout(() => {
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(downloadLink.href);
    }, 100);
  };

  static toUtc = (e: dayjs.Dayjs): Date => {
    const now = e.toDate();
    // return Date.

    return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  };

  static bytesToMegabytes = (bytes: number): number => {
    const megabytes = bytes / (1024 * 1024);
    const roundedMegabytes = parseFloat(megabytes.toFixed(1));
    return roundedMegabytes;
  };

  static downloadBlobFile = (response: AxiosResponse<any, any>, fileName?: string) => {
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName ?? crypto.randomUUID();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleEnterKey = ({ key, keyCode }: any, callback: any) => {
    if (key === 'Enter' || keyCode === 13) {
      callback();
    }
  };

  static roundedNumber = (value?: number) => {
    if (value) {
      return value.toFixed(1);
    }
    return '';
  };

  // For any identifiable object, checks if the object is last in array
  static isLastElement = (object: any, data?: any[]) => {
    if (!data) {
      return false;
    }
    const result = data[data.length - 1].id === object.id;
    return result;
  };
}

export const getDurationTime = (minutes: number, lang: Lang) => {
  const d = Math.floor(minutes / 60 / 24);
  const h = Math.floor(parseInt(`${minutes / 60}`, 10)) - d * 24;
  const m = minutes - 60 * Math.floor(parseInt(`${minutes / 60}`, 10));

  if (d && h && m) {
    return `${d} ${lang.Lms.days.slice(0, 1)}., ${h} ${lang.Lms.hour.slice(0, 1)}., ${m} ${lang.Lms.min}.`.toLowerCase();
  }

  if (h && m) {
    return `${h} ${lang.Lms.hour.slice(0, 1)}., ${m} ${lang.Lms.min}.`.toLowerCase();
  }

  if (h) {
    return `${h} ${lang.Lms.hour.slice(0, 1)}.`.toLowerCase();
  }

  return `${m} ${lang.Lms.min}.`.toLowerCase();
};

export const getBase64Src = (src?: string) => {
  let mimeType = 'image/svg+xml';

  if (!src) {
    return '';
  }

  if (src.startsWith('data') || src.startsWith('media')) {
    return src;
  }

  Object.keys(signatures).forEach((key) => {
    if (src.startsWith(key)) {
      mimeType = signatures[key];
    }
  });

  return `data:${mimeType};base64,${src}`;
};

export const tagColors = ['#554CC1', '#BF4CC1', '#784CC1', '#4C7BC1', '#14A0C7', '#4CC150', '#C18B4C'];

export const uploadImage = (callback: (file: File) => Promise<void>): void => {
  const input = document.createElement('input');

  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
    const file: any = input?.files != null ? input.files[0] : null;

    await callback(file);
  };
};

export default Utils;
