/* eslint-disable operator-linebreak */
import { Button, Form, Input, Modal, Popover, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import classNames from 'classnames';
import Link from 'antd/es/typography/Link';
import { useAuthStore, useLocale } from '../../App';
import { LoginDto } from '../../services/AuthService/dto';
import IconSVG from '../../components/IconSvg';
import IconMenuDown from '../../assets/icons/icon_menu_down.svg';
import http from '../../services/httpService';
import PhonesBar from '../../components/Phones';
import { useLanguageStore } from '../../stores/langStore';
import SpaceBetween from '../../components/Elements/SpaceBetween';
import { CheckAuthUserMethod } from '../../projects/Core/models/AuthMethod';
import { appSettingsStore } from '../../stores/traforySettingStore';
import { getBase64Src } from '../../utils/utils';

import styles from './index.module.css';
import authService from '../../services/AuthService';

enum EntryState {
  tenant,
  email,
  password,
}

interface Dto {
  tenant: string;
  dto: LoginDto;
  entry: EntryState;
}

// FULLY REWRITE THAT SH*T IMPLEMENTATION TRAF-1605

const Index = observer(() => {
  const { authStore } = useAuthStore();
  const { langStore } = useLanguageStore();
  const location = useLocation();
  const navigate = useNavigate();
  const { token, tenant }: any = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { lang } = useLocale();
  const [authMethod, setAuthMethod] = useState<CheckAuthUserMethod>();
  const [tenantTxt, setTenantTxt] = useState({
    isEnter: false,
    text: '',
  });

  const [item, setItem] = useState<Dto>({
    entry: EntryState.tenant,
    tenant: '',
    dto: {
      client_id: 'Trafory_App',
      // grant_type: 'password',
      password: '',
      scope: 'Trafory',
      username: '',
    },
  });

  const tenantName = !token && !tenant && localStorage.getItem('tenancyName');
  const tenantId = localStorage.getItem('tenantId');

  const login = async () => {
    if (tenantName) {
      if (item.dto.username !== '' && item.dto.password !== '') {
        await authStore.login(tenantName, item.dto, lang);
      }
    }
  };

  const onForgotPasswordClick = async () => {
    if (authStore.userData.id === '') return;

    const result = await http.post('/api/passwords-management/send-password-reset-code', {
      appName: 'React',
      userId: authStore.userData.id,
    }, {
      headers: {
        __tenant: tenantId || tenantName,
      },
    });
    if (result.status === 204) {
      Modal.info(
        {
          title: lang.CoreHr.passwordChanging,
          content: lang.CoreHr.changePasswordConfirmed,
        },
      );
    }
  };

  const selectTenant = async () => {
    localStorage.removeItem('tenancyName');

    if (tenantTxt.text === '') {
      return;
    }
    await authStore.checkTenant(tenantTxt.text, lang);
    if (authStore.tenant?.success) {
      localStorage.setItem('tenancyName', authStore.tenant?.name);
      setItem({ ...item, entry: EntryState.email });
    }
  };

  const setPredefinedTenant = async (name: string) => {
    await authStore.checkTenant(name, lang);
    if (authStore.tenant?.success) {
      localStorage.setItem('tenancyName', name);
      setItem({ ...item, entry: EntryState.email });
      setTenantTxt({ isEnter: true, text: name });
      navigate('/');
    }
  };

  useEffect(() => {
    appSettingsStore.updateStyles();
  }, [tenantName]);

  useEffect(() => {
    if (tenant) {
      setPredefinedTenant(tenant);
    }
  }, [tenant]);

  useEffect(() => {
    const setTenantId = async () => {
      const result = await authService.checkTenant(authStore.tenant!.name);

      localStorage.setItem('tenantId', result.tenantId);
    };

    if (authStore.tenant?.name) {
      setTenantId();
    }
  }, [authStore.tenant?.name]);

  useEffect(() => {
    const getData = async () => {
      if (token) {
        authStore.isGlobalLoading = true;
        try {
          await authStore.getAuthToken({
            client_id: 'Trafory_App',
            grant_type: 'whoami_kz',
            password: token,
            scope: 'Trafory',
          });
          await localStorage.setItem('token', `Bearer ${authStore.authToken?.access_token}`);
          await authStore.getConfig();
          await langStore.getLocale();
        } finally {
          navigate('/');
          authStore.isGlobalLoading = false;
        }
      }
    };
    getData();
  }, [token]);

  const clearTenant = () => {
    authStore.logout();
    setItem({ ...item, entry: EntryState.tenant });
    appSettingsStore.read();
  };

  const enterEmail = async () => {
    setAuthMethod(undefined);
    if (item.dto.username !== '') {
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        __tenant: tenantId || tenantTxt.text,
      };
      try {
        authStore.isLoading = true;

        const data = {
          userName: item.dto.username,
        };

        const result = await http.post('/auth/find-user-in-system', data, { headers: headers });

        const settings = result.data as CheckAuthUserMethod;

        const { email, id, userName } = settings;

        authStore.userData = {
          ...authStore.userData,
          email,
          id,
          userName,
        };

        setAuthMethod(settings);
        if (settings.authMethod.methodSettings?.isDefaultPasswordUsed && settings.shouldChangePasswordOnNextLogin) {
          navigate(`/account/create-password?userId=${settings.id}&tenantId=${settings.tenantId}&shouldConfirmPhoneNextLogin=${settings.shouldConfirmPhoneNextLogin}`);
        }
        setItem({ ...item, dto: { ...item.dto, grant_type: settings.grantType }, entry: EntryState.password });
      } finally {
        authStore.isLoading = false;
      }
    }
  };

  return (
    <div className={styles.area}>
      {appSettingsStore.data?.authCoverPicBase64 ? (
        <div style={{ backgroundImage: `url(${getBase64Src(appSettingsStore.data?.authCoverPicBase64)})` }} className={styles.picture} />
      ) : (
        <PhonesBar className={styles.picture} />
      )}
      <div className={classNames(styles.details, 'vertical-form')}>
        <div className={styles.header}>
          <img src={getBase64Src(appSettingsStore.entryLogo)} className={styles.logo} alt='logo' />
        </div>
        {item.entry === EntryState.tenant && (
          <Form noValidate className={styles.form} labelAlign='left' layout='vertical'>
            <Form.Item label={<div style={{ fontSize: 18, marginBottom: 4 }}>{lang.CoreHr.companyLabel}</div>}>
              <Tooltip title={lang.CoreHr.loginPageTenantTooltip}>
                <Input
                  placeholder={lang.CoreHr.enterCompanyName}
                  style={{ height: 40 }}
                  value={tenantTxt.text}
                  onChange={(e) => setTenantTxt({ isEnter: true, text: e.target.value })}
                  onPressEnter={selectTenant}
                />
              </Tooltip>

            </Form.Item>
            <Form.Item>
              <Button style={{ width: '100%' }} disabled={authStore.isLoading} shape='round' onClick={selectTenant} type='primary'>
                {lang.CoreHr.proceed}
              </Button>
            </Form.Item>
          </Form>
        )}
        {item.entry === EntryState.email && (
          <Form noValidate className={styles.form} labelAlign='left' layout='vertical'>
            <Tooltip title={lang.CoreHr.loginPageTenantTooltip}>
              <div className={styles.tenantLabel}>{tenantTxt.text}</div>
            </Tooltip>
            <Form.Item label={<div style={{ fontWeight: 500, fontSize: 14 }}>{lang.Lms.loginName}</div>}>
              <Tooltip title={lang.CoreHr.loginPageUsernameTooltip}>
                <Input
                  type='text'
                  placeholder={lang.CoreHr.enterLogin}
                  value={item.dto.username}
                  onChange={(e) => setItem({ ...item, dto: { ...item.dto, username: e.target.value } })}
                  onPressEnter={enterEmail}
                  autoComplete="username"
                  name="username"
                />
              </Tooltip>
            </Form.Item>
            <Form.Item>
              <SpaceBetween>
                <Button shape='round' disabled={authStore.isLoading} onClick={clearTenant}>
                  {lang.Lms.back}
                </Button>
                <Button shape='round' disabled={authStore.isLoading} onClick={enterEmail} type='primary'>
                  {lang.CoreHr.proceed}
                </Button>
              </SpaceBetween>
            </Form.Item>
          </Form>
        )}
        {item.entry === EntryState.password && (
          <Form noValidate className={styles.form} labelAlign='left' layout='vertical'>
            <Tooltip title={lang.CoreHr.loginPageTenantTooltip}>
              <div className={styles.tenantLabel}>{tenantTxt.text}</div>
            </Tooltip>
            <Tooltip title={lang.CoreHr.loginPageUsernameTooltip}>
              <div className={styles.loginLabel}>{item.dto.username}</div>
            </Tooltip>
            <Form.Item label={<div style={{ fontWeight: 500, fontSize: 14 }}>{lang.Lms.password}</div>}>
              <Input.Password
                style={{ fontSize: 16 }}
                type='password'
                placeholder={lang.Lms.enterPassword}
                value={item.dto.password}
                onChange={(e) => setItem({ ...item, dto: { ...item.dto, password: e.target.value } })}
                onPressEnter={login}
                name="password"
              />
            </Form.Item>
            {authMethod?.authMethod.grantType === 1 && (
              <div className={styles.forgot} onClick={onForgotPasswordClick}>
                <a>{`${lang.Lms.forgotYourPassword}? `}</a>
              </div>
            )}
            <Form.Item>
              <SpaceBetween>
                <Button disabled={authStore.isLoading} shape='round' onClick={() => setItem({ ...item, entry: EntryState.email })}>
                  {lang.Lms.back}
                </Button>
                <Button disabled={authStore.isLoading} shape='round' onClick={login} type='primary'>
                  {lang.Lms.login}
                </Button>
              </SpaceBetween>
            </Form.Item>
          </Form>
        )}
        <Link className={styles.footerLink} href={appSettingsStore.data?.securityPolicyLink} target='_blank'>{lang.Trafory.personalDataProcessingPolicy}</Link>
      </div>
      <SelectLanguage />
    </div>
  );
});
export default Index;
const SelectLanguage = observer(() => {
  const { authStore } = useAuthStore();
  const { langStore } = useLanguageStore();
  const [isOpened, setOpened] = useState(false);
  const { lang } = useLocale();
  const select = async (e: any) => {
    await lang.setLanguage({
      culture: e.cultureName,
      uiCulture: e.uiCultureName,
    });
    await langStore.getLocale();
  };
  const handleOpen = () => {
    setOpened(!isOpened);
  };
  return (
    <div className={styles.lang}>
      <Popover
        placement='bottom'
        trigger='click'
        overlayInnerStyle={{ padding: 0 }}
        open={isOpened}
        onOpenChange={handleOpen}
        content={
          <div className={styles.languages}>
            {authStore.app?.localization.languages.map((e) => (
              <p key={e.cultureName} onClick={() => !langStore.isLoading && select(e)}>
                {e.uiCultureName}
              </p>
            ))}
          </div>
        }
      >
        <div className={styles.language}>
          {localStorage.getItem('Locale') ?? 'en'}
          <IconSVG src={IconMenuDown} />
        </div>
      </Popover>
    </div>
  );
});
