import http from '../../../services/httpService';
import { ApplyFormResponseDto, GetDynamicFormOutput } from './dto';

class Index {
  public async read(id?: string, tenantName?: string): Promise<GetDynamicFormOutput> {
    const result = await http.get(`/api/corehr/forms/${id}`, {
      headers: {
        __tenant: tenantName,
      },
    });
    return result.data;
  }

  public async apply(e: ApplyFormResponseDto, tenant: string) {
    const result = await http.post('/api/corehr/forms/apply-form-response', e, {
      headers: {
        __tenant: tenant,
      },
    });
    return result.status === 204;
  }
}

export default new Index();
