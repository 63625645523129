import { Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import AuthView from './Login';
import ResetView from './Reset';
import DynamicFormView from './DynamicForm';
import CreatePasswordView from './CreatePassword';

function Index() {
  return (
    <Routes>
      <Route index element={<AuthView />} />
      <Route path='account/reset-password' element={<ResetView />} />
      <Route path='account/create-password' element={<CreatePasswordView />} />
      <Route path='dynamic' element={<DynamicFormView />} />
      <Route path='*' element={<Initial />} />
    </Routes>
  );
}

export default observer(Index);

function Initial() {
  const nav = useNavigate();
  useEffect(() => {
    // (localStorage.getItem('isAdmin')) ? nav("/core") : nav("/");
    nav('/');

    // eslint-disable-next-line
  }, []);
  return <div />;
}
