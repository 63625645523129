import Lang from './lang';

type moduledDirectTranslateType = (moduleName: string) => (providedKey: string) => string;

const moduledDirectTranslate: moduledDirectTranslateType = (moduleName) => (providedKey) => {
  if (!providedKey || !moduleName) {
    console.error(`getTranslationOutsideReact.moduledDirectTranslate: Wrong value provided: providedKey: ${providedKey}, moduleName: ${moduleName}`);
    return '';
  }

  if (!Lang.translations?.[moduleName]) return providedKey;

  return Lang.translations?.[moduleName].texts[providedKey] || providedKey;
};

export const getTranslationOutsideReact = ({
  CoreHr: moduledDirectTranslate('CoreHr'),
  Hcm: moduledDirectTranslate('Hcm'),
  Lms: moduledDirectTranslate('Lms'),
  Trafory: moduledDirectTranslate('Trafory'),
});
