import { createContext, Ref, useContext } from 'react';

export type GuideRefsContextType = {
  id: number,
  ref: Ref<any>
};

export type UserGuide = {
  guideRefs: GuideRefsContextType[]
};

export const HintRefsContext = createContext<UserGuide>({
  guideRefs: [],
});

export const useGuideRefs = () => useContext(HintRefsContext);
