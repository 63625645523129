import { Outlet, RouteObject } from 'react-router-dom';
import { lazy } from 'react';
import MainScaffold from '../MainScaffold';
import NotFound from '../NotFound';

const Stub = lazy(() => import('../Stub'));

const Home = lazy(() => import('../../projects/Lms/views/Home'));

const Workspace = lazy(() => import('../../projects/Core/views/Workspace'));
const WorkspaceMain = lazy(() => import('../../projects/Core/views/WorkspaceMain'));
const WorkspaceSubordinate = lazy(() => import('../../projects/Core/views/WorkspaceSubordinate'));
const WorkspaceEducation = lazy(() => import('../../projects/Core/views/WorkspaceEducation'));
const WorkspaceQualification = lazy(() => import('../../projects/Core/views/WorkspaceQualification'));

const EmployeeView = lazy(() => import('../../projects/Core/views/EmployeeView'));
const JobInfo = lazy(() => import('../../projects/Core/views/EmployeeView/views/JobInfo'));
const Profile = lazy(() => import('../../projects/Core/views/EmployeeView/views/Profile'));
const Account = lazy(() => import('../../projects/Core/views/EmployeeView/views/Account'));
const Education = lazy(() => import('../../projects/Core/views/EmployeeView/views/Education'));
const Evaluation = lazy(() => import('../../projects/Core/views/EmployeeView/views/Evaluation'));

const Posts = lazy(() => import('../../projects/Global/views/Posts'));
const PostsTree = lazy(() => import('../../projects/Global/views/Posts/PostsTree'));
const Digest = lazy(() => import('../../projects/Global/views/Posts/views/Digest'));
const PostsEditor = lazy(() => import('../../projects/Global/views/Posts/views/Editor'));

const ContentLibrary = lazy(() => import('../../projects/CoreClient/views/ContentLibrary'));
const CoursesTree = lazy(() => import('../../projects/Lms/views/CoursesTree'));

const Calendar = lazy(() => import('../../projects/Lms/views/Calendar'));
const Invitation = lazy(() => import('../../projects/Lms/views/Calendar/views/Invitation'));

const TreeNodes = lazy(() => import('../../projects/Core/views/BusinessUnits/TreeNodes'));
const OrgChart = lazy(() => import('../../projects/Core/views/OrgChart'));

const OrgStructure = lazy(() => import('../../projects/CoreClient/views/OrgStructure'));
const BusinessUnit = lazy(() => import('../../projects/Core/views/BusinessUnit'));

const BusinessStaffUnits = lazy(() => import('../../projects/Core/views/BusinessUnit/StaffUnits'));
const DivisionsView = lazy(() => import('../../projects/Core/views/BusinessUnit/DivisionsView'));
const JobsView = lazy(() => import('../../projects/Core/views/BusinessUnit/JobsView'));

const CoreClientEmployees = lazy(() => import('../../projects/CoreClient/views/Employees'));
const EmployeesInfosTable = lazy(() => import('../../projects/CoreClient/views/EmployeesInfosTable'));
const VacationSchedule = lazy(() => import('../../projects/Core/views/VacationSchedule'));

const LearningPaths = lazy(() => import('../../projects/Lms/views/LearningPaths'));
const LearningPath = lazy(() => import('../../projects/Lms/views/LearningPath'));
const LearningPathArchitecture = lazy(() => import('../../projects/Lms/views/LearningPath/views/Architecture'));
const LearningPathAnnotation = lazy(() => import('../../projects/Lms/views/LearningPath/views/Annotation'));

const MeetingsListView = lazy(() => import('../../projects/Lms/views/Meetings/views/ListView'));
const MeetingsEditView = lazy(() => import('../../projects/Lms/views/Meetings/views/EditView'));

const Groups = lazy(() => import('../../projects/Lms/views/Groups'));
const LmsGroup = lazy(() => import('../../projects/Lms/views/LmsGroup'));

const LmsGroupControl = lazy(() => import('../../projects/Lms/views/LmsGroup/views/Control'));
const LmsGroupControlCourses = lazy(() => import('../../projects/Lms/views/LmsGroup/views/Control/views/Courses'));
const LmsGroupControlDetails = lazy(() => import('../../projects/Lms/views/LmsGroup/views/Control/views/Details'));
const LmsGroupControlQuestions = lazy(() => import('../../projects/Lms/views/LmsGroup/views/Control/views/Questions'));
const LmsGroupControlStudents = lazy(() => import('../../projects/Lms/views/LmsGroup/views/Control/views/Students'));
const LmsGroupControlHistory = lazy(() => import('../../projects/Lms/views/LmsGroup/views/Control/views/History'));

const LmsGroupCuratorStudentsChat = lazy(() => import('../../projects/Lms/views/LmsGroup/views/CuratorStudentsChat'));
const LmsGroupReports = lazy(() => import('../../projects/Lms/views/LmsGroup/views/Reports'));
const LmsGroupSettings = lazy(() => import('../../projects/Lms/views/LmsGroup/views/Settings'));
const LmsGroupStudents = lazy(() => import('../../projects/Lms/views/LmsGroup/views/Students'));

const HcmReports = lazy(() => import('../../projects/Hcm/views/Reports'));
const LmsReports = lazy(() => import('../../projects/Lms/views/Reports'));
const PostsReports = lazy(() => import('../../projects/Lms/views/Reports/postsReports'));

const LearningPathPassings = lazy(() => import('../../projects/Lms/views/LearningPathPassings'));
const LearningPathPassing = lazy(() => import('../../projects/Lms/views/LearningPathPassing'));
const LearningPathPassingSummary = lazy(() => import('../../projects/Lms/views/LearningPathPassing/views/Summary'));
const LearningPathPassingChatWithCurator = lazy(() => import('../../projects/Lms/views/LearningPathPassing/views/ChatWithCurator'));

const CoursePassing = lazy(() => import('../../projects/Lms/views/CoursePassing'));
const CoursePassingSummary = lazy(() => import('../../projects/Lms/views/CoursePassing/views/Summary'));

const IndividualDevPlan = lazy(() => import('../../projects/Hcm/views/IndividualDevPlan'));
const IndividualDevPlanAssign = lazy(() => import('../../projects/Hcm/views/IndividualDevPlanAssign'));

const LessonPassing = lazy(() => import('../../projects/Lms/views/LessonPassing'));
const LessonPassingApprovals = lazy(() => import('../../projects/Lms/views/LessonPassing/views/Approvals'));
const LessonPassingContent = lazy(() => import('../../projects/Lms/views/LessonPassing/views/Content'));
const LessonPassingQuestions = lazy(() => import('../../projects/Lms/views/LessonPassing/views/Questions'));

const KnowledgeBase = lazy(() => import('../../projects/CoreClient/views/KnowledgeBase'));

const CoursesForReading = lazy(() => import('../../projects/Lms/views/Course/views/CoursesForReading'));

const MatrixPotential = lazy(() => import('../../projects/Hcm/views/MatrixPotential'));
const MatrixPotentialEmployees = lazy(() => import('../../projects/Hcm/views/MatrixPotential/views/Employees'));
const MatrixPotentialManagers = lazy(() => import('../../projects/Hcm/views/MatrixPotential/views/Managers'));
const MatrixPotentialSettings = lazy(() => import('../../projects/Hcm/views/MatrixPotential/views/Settings'));

const Surveys = lazy(() => import('../../projects/Hcm/views/Surveys'));
const SurveysColleagues = lazy(() => import('../../projects/Hcm/views/Surveys/views/Colleagues'));
const SurveysPersonal = lazy(() => import('../../projects/Hcm/views/Surveys/views/Personal'));
const SurveysSurvey = lazy(() => import('../../projects/Hcm/views/Surveys/views/Survey'));
const SurveysSelectPerson = lazy(() => import('../../projects/Hcm/views/Surveys/views/SelectPerson'));

const HcmEvaluation = lazy(() => import('../../projects/Hcm/views/Evaluation'));
const HcmEvaluationEmployees = lazy(() => import('../../projects/Hcm/views/Evaluation/views/Employees'));
const HcmEvaluationManagers = lazy(() => import('../../projects/Hcm/views/Evaluation/views/Managers'));
const HcmEvaluationCompany = lazy(() => import('../../projects/Hcm/views/Evaluation/views/Company'));
const HcmEvaluationDetails = lazy(() => import('../../projects/Hcm/views/Evaluation/views/Details'));

const HcmEvaluationDetailsGoals = lazy(() => import('../../projects/Hcm/views/Evaluation/views/Details/Components/Goals'));

const Files = lazy(() => import('../../projects/CoreClient/views/Files'));
const Library = lazy(() => import('../../projects/Global/views/Library'));
const ScormCourses = lazy(() => import('../../projects/Lms/views/ScormCourses'));

const Course = lazy(() => import('../../projects/Lms/views/Course'));
const Courses = lazy(() => import('../../projects/Lms/views/Courses'));
const CourseAnnotation = lazy(() => import('../../projects/Lms/views/Course/views/Annotation'));
const CourseArchitecture = lazy(() => import('../../projects/Lms/views/Course/views/Architecture'));
const CourseCollaborators = lazy(() => import('../../projects/Lms/views/Course/views/Collaborators'));
const ReadingAccess = lazy(() => import('../../projects/Lms/views/Course/views/ReadingAccess'));
const AutoAssignment = lazy(() => import('../../projects/Lms/views/Course/views/AutoAssignment'));
const CourseSettings = lazy(() => import('../../projects/Lms/views/Course/views/Settings'));
const CourseVersions = lazy(() => import('../../projects/Lms/views/Course/views/Versions'));
const CourseCompetences = lazy(() => import('../../projects/Lms/views/Course/views/Competences'));

const CollaborantCourses = lazy(() => import('../../projects/Lms/views/CollaborantCourses'));

const DirectoryCompetencies = lazy(() => import('../../projects/Hcm/views/Directories/views/Competencies'));
const DirectoryCompetenciesCategories = lazy(() => import('../../projects/Hcm/views/Directories/views/Competencies/views/Categories'));
const DirectoryCompetenciesList = lazy(() => import('../../projects/Hcm/views/Directories/views/Competencies/views/List'));
const DirectoryCompetenciesScale = lazy(() => import('../../projects/Hcm/views/Directories/views/Competencies/views/Scale'));
const DirectoryCompetenciesListCreate = lazy(() => import('../../projects/Hcm/views/Directories/views/Competencies/views/List/views/Create'));
const DirectoryCompetenciesCategoriesEdit = lazy(() => import('../../projects/Hcm/views/Directories/views/Competencies/views/Categories/views/EditView'));
const DirectoryCompetenciesCategoriesCreate = lazy(() => import('../../projects/Hcm/views/Directories/views/Competencies/views/Categories/views/Create'));
const DirectoryCompetenciesListDetail = lazy(() => import('../../projects/Hcm/views/Directories/views/Competencies/views/List/views/EditView'));

const DirectoriesJobsList = lazy(() => import('../../projects/Hcm/views/Directories/views/JobProfile/List'));
const DirectoriesEditJobProfile = lazy(() => import('../../projects/Hcm/views/Directories/views/JobProfile/EditJobProfile'));
const JobProfileDetails = lazy(() => import('../../projects/Hcm/views/Directories/views/JobProfile/JobProfileDetails'));
const JobProfileVersions = lazy(() => import('../../projects/Hcm/views/Directories/views/JobProfile/JobProfileVersions'));
const DirectoryScoringMethods = lazy(() => import('../../projects/Hcm/views/Directories/views/ScoringMethods'));
const DirectoryScoringMethodsDetails = lazy(() => import('../../projects/Hcm/views/Directories/views/ScoringMethods/views/DetailsView'));
const DirectoryScoringMaterials = lazy(() => import('../../projects/Hcm/views/Directories/views/ScoringMaterials'));
const DirectorySubjects = lazy(() => import('../../projects/Hcm/views/Directories/views/Subjects'));

const DirectoryDevPlanMaterials = lazy(() => import('../../projects/Hcm/views/Directories/views/DevPlanMaterials'));

const Support = lazy(() => import('../../projects/Core/views/Support'));
const Errors = lazy(() => import('../../projects/CoreClient/views/Errors'));

const Dashboard = lazy(() => import('../../projects/Core/views/Dashboard'));

const Forms = lazy(() => import('../../projects/Core/views/Forms'));
const FormTabbed = lazy(() => import('../../projects/Core/views/Forms/views'));
const FormInquiries = lazy(() => import('../../projects/Core/views/Forms/views/Inquries'));
const FormSettings = lazy(() => import('../../projects/Core/views/Forms/views/Settings'));
const FormEdit = lazy(() => import('../../projects/Core/views/Forms/views/Form'));

const BusinessUnits = lazy(() => import('../../projects/Core/views/BusinessUnitsView'));
const BusinessUnitsList = lazy(() => import('../../projects/Core/views/BusinessUnits/TreeNodes'));
const BusinessUnitsChart = lazy(() => import('../../projects/Core/views/OrgChart'));

const CoreEmployees = lazy(() => import('../../projects/Core/views/Employees'));
const StaffUnits = lazy(() => import('../../projects/Core/views/StaffUnits'));
const EmployeesTree = lazy(() => import('../../projects/Core/views/EmployeesTree'));

const EmployeeViewProfile = lazy(() => import('../../projects/Core/views/EmployeeView/views/Profile'));
const EmployeeViewAccount = lazy(() => import('../../projects/Core/views/EmployeeView/views/Account'));
const EmployeeViewJobInfo = lazy(() => import('../../projects/Core/views/EmployeeView/views/JobInfo'));
const EmployeeViewEducation = lazy(() => import('../../projects/Core/views/EmployeeView/views/Education'));
const EmployeeViewEvaluation = lazy(() => import('../../projects/Core/views/EmployeeView/views/Evaluation'));

const Directories = lazy(() => import('../../projects/Core/views/Directories'));
const DirectoriesIntegrations = lazy(() => import('../../projects/Core/views/Directories/views/Integrations'));
const DirectoriesBusinessUnitTypes = lazy(() => import('../../projects/Core/views/Directories/views/BusinessUnitTypes'));
const DirectoriesDivisionTypes = lazy(() => import('../../projects/Core/views/Directories/views/DivisionTypes'));
const DirectoriesImport = lazy(() => import('../../projects/Core/views/Directories/views/Import'));
const ImportCompanies = lazy(() => import('../../projects/Core/views/Import/Companies'));
const ImportJobs = lazy(() => import('../../projects/Core/views/Import/Jobs'));
const ImportUsers = lazy(() => import('../../projects/Core/views/Import/Users'));

const SettingsRoles = lazy(() => import('../../projects/Core/views/Settings/views/Roles'));
const SettingsLanguages = lazy(() => import('../../projects/Core/views/Settings/views/Languages'));
const SettingsNotifications = lazy(() => import('../../projects/Core/views/Settings/views/Notifications'));
const PortalSetting = lazy(() => import('../../projects/Core/views/Settings/views/PortalSetting'));
const SettingsEmailTemplates = lazy(() => import('../../projects/Core/views/Settings/views/EmailTemplates'));
const SettingsUserAccounts = lazy(() => import('../../projects/Core/views/Settings/views/UserAccounts'));
const SettingsAuthMethods = lazy(() => import('../../projects/Core/views/Settings/views/AuthMethods'));
const SettingsLdap = lazy(() => import('../../projects/Core/views/Settings/views/AuthMethods/views/Ldap'));
const AuthMethodSystem = lazy(() => import('../../projects/Core/views/Settings/views/AuthMethods/views/Trafory'));

const LessonEditor = lazy(() => import('../../projects/Lms/views/Lesson'));

const Bookmarks = lazy(() => import('../../projects/Core/views/Bookmarks'));

export const routes: RouteObject[] = [
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '',
    element: <MainScaffold />,
    children: [
      {
        path: '/',
        index: true,
        element: <Home />,
      },
      {
        path: 'workspace',
        element: <Workspace />,
        children: [
          {
            path: 'main',
            element: <WorkspaceMain />,
          },
          {
            path: 'subordinate',
            element: <WorkspaceSubordinate />,
          },
          {
            path: 'education',
            element: <WorkspaceEducation />,
          },
          {
            path: 'qualification',
            element: <WorkspaceQualification />,
          },
        ],
      },
      // Profile
      {
        path: 'employees/profile/:id',
        element: <EmployeeView />,
        children: [
          {
            path: 'job-summary',
            element: <JobInfo />,
          },
          {
            path: 'profile',
            element: <Profile />,
          },
          {
            path: 'account',
            element: <Account />,
          },
          {
            path: 'education',
            element: <Education />,
          },
          {
            path: 'evaluation',
            element: <Evaluation />,
          },
        ],
      },
      // Core HR
      {
        path: 'company',
        children: [
          {
            path: 'news',
            children: [
              {
                index: true,
                element: <Posts isDigest />,
              },
              {
                path: ':id',
                element: <Digest />,
              },
            ],
          },
          {
            path: 'calendar-invitations',
            children: [
              {
                index: true,
                element: <Calendar />,
              },
              {
                path: ':id',
                element: <Invitation />,
              },
            ],
          },
          {
            path: 'org-structure',
            element: <OrgStructure />,
            children: [
              {
                path: 'list',
                element: <TreeNodes isClientSide />,
              },
              {
                path: 'chart',
                element: <OrgChart />,
              },
            ],
          },
          // cant connect to org-structure coz of bad implementation of TabbedView component
          {
            path: 'org-structure/details',
            children: [
              {
                path: ':id',
                element: <BusinessUnit isClientSide />,
                children: [
                  {
                    path: 'staff',
                    element: <BusinessStaffUnits isClientSide />,
                  },
                  {
                    path: 'jobs',
                    element: <JobsView isClientSide />,
                  },
                  {
                    path: 'divisions',
                    element: <DivisionsView isClientSide />,
                  },
                ],
              },
            ],
          },
          {
            path: 'employees',
            element: <CoreClientEmployees />,
          },
          {
            path: 'employees-infos',
            element: <EmployeesInfosTable />,
          },
          {
            path: 'vacations',
            element: <VacationSchedule />,
          },
          {
            path: 'services',
            element: <Stub />,
          },
          {
            path: 'posts-catalog',
            element: <PostsTree isEmployeeRole />,
          },
          {
            path: 'bookmarks',
            element: <Bookmarks />,
          },
        ],
      },
      {
        path: 'adaptation',
        children: [
          {
            path: 'programs',
            children: [
              {
                index: true,
                element: <LearningPaths isAdaptation />,
              },
              {
                path: ':id',
                element: <LearningPath isAdaptation />,
                children: [
                  {
                    path: 'architecture',
                    element: <LearningPathArchitecture />,
                  },
                  {
                    path: 'annotation',
                    element: <LearningPathAnnotation />,
                  },
                ],
              },
            ],
          },
        ],
      },
      /* LEARNING CENTER */
      {
        path: 'learning-center',
        children: [
          {
            path: 'events-plan',
            children: [
              {
                index: true,
                element: <MeetingsListView />,
              },
              {
                path: ':id',
                element: <MeetingsEditView />,
              },
            ],
          },
          {
            path: 'groups',
            children: [
              {
                index: true,
                element: <Groups />,
              },
              {
                path: ':id',
                element: <LmsGroup />,
                children: [
                  {
                    path: 'control',
                    element: <LmsGroupControl />,
                    children: [
                      {
                        index: true,
                        element: <LmsGroupControlCourses />,
                      },
                      {
                        path: ':courseId/:lessonId',
                        element: <LmsGroupControlDetails />,
                        children: [
                          {
                            path: 'communication',
                            element: <LmsGroupControlQuestions />,
                          },
                          {
                            path: 'participants',
                            element: <LmsGroupControlStudents />,
                          },
                          {
                            path: 'history',
                            element: <LmsGroupControlHistory />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'chat-curator',
                    element: <LmsGroupCuratorStudentsChat />,
                  },
                  {
                    path: 'reports',
                    element: <LmsGroupReports />,
                  },
                  {
                    path: 'settings',
                    element: <LmsGroupSettings />,
                  },
                  {
                    path: 'students',
                    element: <LmsGroupStudents />,
                  },
                ],
              },
            ],
          },
          {
            path: 'reports',
            element: <HcmReports />,
            children: [
              {
                path: 'study',
                element: <LmsReports />,
              },
              {
                path: 'posts',
                element: <PostsReports />,
              },
            ],
          },
        ],
      },
      /* EDUCATION */
      {
        path: 'education',
        children: [
          {
            path: 'courses',
            children: [
              {
                index: true,
                element: <LearningPathPassings />,
              },
              {
                path: ':id',
                element: <LearningPathPassing />,
                children: [
                  {
                    path: 'summary',
                    element: <LearningPathPassingSummary />,
                  },
                  {
                    path: 'chat',
                    element: <LearningPathPassingChatWithCurator />,
                  },
                ],
              },
              {
                path: 'play',
                children: [
                  {
                    path: ':id',
                    element: <CoursePassing />,
                    children: [
                      {
                        path: 'summary',
                        element: <CoursePassingSummary />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'personal-dev-plan',
            element: <IndividualDevPlan />,
          },
          {
            path: 'personal-dev-plan',
            element: <IndividualDevPlanAssign />,
          },
          {
            path: 'lesson-passing/:coursePassingId/:lessonId',
            element: <LessonPassing />,
            children: [
              {
                path: 'player',
                element: <LessonPassingContent />,
              },
              {
                path: 'approvals',
                element: <LessonPassingApprovals />,
              },
              {
                path: 'questions',
                element: <LessonPassingQuestions />,
              },
            ],
          },
          {
            path: 'knowledge-base',
            element: <KnowledgeBase />,
            children: [
              {
                path: 'materials',
                element: <Stub />,
              },
            ],
          },
          {
            path: 'courses-for-reading',
            children: [
              {
                index: true,
                element: <CoursesForReading />,
              },
              {
                path: 'in-progress',
                element: <CoursesForReading isPassing />,
              },
            ],
          },
          {
            path: 'matrix',
            element: <MatrixPotential />,
            children: [
              {
                path: 'employees',
                element: <MatrixPotentialEmployees />,
              },
              {
                path: 'managers',
                element: <MatrixPotentialManagers />,
              },
              {
                path: 'settings',
                element: <MatrixPotentialSettings />,
              },
            ],
          },
        ],
      },
      // SCORE TALENTS (HCM)
      {
        path: 'score-talents',
        children: [
          {
            path: 'surveys',
            element: <Surveys />,
            children: [
              {
                path: 'colleagues',
                element: <SurveysColleagues />,
              },
              {
                path: 'personal',
                element: <SurveysPersonal />,
              },
            ],
          },
          {
            path: 'select-person/:id',
            element: <SurveysSelectPerson />,
          },
          {
            path: 'survey/:id',
            element: <SurveysSurvey />,
          },
          {
            path: 'scoring',
            element: <HcmEvaluation />,
            children: [
              {
                path: 'employees',
                element: <HcmEvaluationEmployees />,
              },
              {
                path: 'managers',
                element: <HcmEvaluationManagers />,
              },
              {
                path: 'company',
                element: <HcmEvaluationCompany />,
              },
            ],
          },
          {
            path: 'evaluation/:id',
            element: <HcmEvaluationDetails />,
          },
        ],
      },
      {
        path: 'employees-goals',
        element: <HcmEvaluationDetailsGoals />,
      },
      /* File Management and Posts Edit */
      {
        path: 'content',
        children: [
          {
            path: 'files',
            element: <Files />,
            children: [
              {
                path: 'general',
                element: <Library />,
              },
              {
                path: 'scorm',
                element: <ScormCourses />,
              },
            ],
          },
          {
            path: 'library',
            element: <ContentLibrary />,
            children: [
              {
                path: 'posts-tree',
                element: <PostsTree />,
              },
              {
                path: 'courses-tree',
                element: <CoursesTree />,
              },
            ],
          },
          {
            path: 'constructor',
            children: [
              {
                index: true,
                element: <Courses />,
              },
              {
                path: ':id/:vid',
                element: <Course />,
                children: [
                  {
                    path: 'architecture',
                    element: <CourseArchitecture />,
                  },
                  {
                    path: 'annotation',
                    element: <CourseAnnotation />,
                  },
                  {
                    path: 'settings',
                    element: <CourseSettings />,
                  },
                  {
                    path: 'collaborants',
                    element: <CourseCollaborators />,
                  },
                  {
                    path: 'versions',
                    element: <CourseVersions />,
                  },
                  {
                    path: 'reading-access',
                    element: <ReadingAccess />,
                  },
                  {
                    path: 'auto-assignment',
                    element: <AutoAssignment />,
                  },
                  {
                    path: 'competencies',
                    element: <CourseCompetences />,
                  },
                ],
              },
              {
                path: 'lesson/:id/:vid/:lessonId',
                element: <LessonEditor />,
              },
              // {
              //   path: 'course-preview/:id/:vid',
              //   element: <CoursePreviewView />,
              // },
            ],
          },
          {
            path: 'posts',
            children: [
              {
                index: true,
                element: <Posts />,
              },
              {
                path: ':id',
                element: <PostsEditor />,
              },
            ],
          },
          {
            path: 'create-education-guide',
            children: [
              {
                index: true,
                element: <LearningPaths />,
              },
              {
                path: ':id',
                element: <LearningPath />,
                children: [
                  {
                    path: 'architecture',
                    element: <LearningPathArchitecture />,
                  },
                  {
                    path: 'annotation',
                    element: <LearningPathAnnotation />,
                  },
                ],
              },
            ],
          },
          {
            path: 'collaboration',
            element: <CollaborantCourses />,
          },
        ],
      },
      {
        path: 'directories',
        children: [
          {
            path: 'competencies',
            element: <DirectoryCompetencies />,
            children: [
              {
                path: 'categories',
                element: <DirectoryCompetenciesCategories />,
              },
              {
                path: 'list',
                element: <DirectoryCompetenciesList />,
              },
              {
                path: 'scale',
                element: <DirectoryCompetenciesScale />,
              },
            ],
          },
          {
            path: 'competencies/list/:id',
            element: <DirectoryCompetenciesListDetail />,
          },
          {
            path: 'competencies/list/create',
            element: <DirectoryCompetenciesListCreate />,
          },
          {
            path: 'competencies/categories/edit/:id',
            element: <DirectoryCompetenciesCategoriesEdit />,
          },
          {
            path: 'competencies/categories/create',
            element: <DirectoryCompetenciesCategoriesCreate />,
          },
          {
            path: 'jobs',
            children: [
              {
                index: true,
                element: <DirectoriesJobsList />,
              },
              {
                path: 'job-profile/:jobProfileId/:jobProfileVersionId',
                element: <DirectoriesEditJobProfile />,
                children: [
                  {
                    path: 'details',
                    element: <JobProfileDetails />,
                  },
                  {
                    path: 'versions',
                    element: <JobProfileVersions />,
                  },
                ],
              },
            ],
          },
          {
            path: 'methods',
            children: [
              {
                index: true,
                element: <DirectoryScoringMethods />,
              },
              {
                path: ':id',
                element: <DirectoryScoringMethodsDetails />,
              },
            ],
          },
          {
            path: 'materials',
            element: <DirectoryScoringMaterials />,
          },
          {
            path: 'subjects',
            element: <DirectorySubjects />,
          },
          {
            path: 'plan-materials',
            element: <DirectoryDevPlanMaterials />,
          },
        ],
      },
      {
        path: 'support',
        element: <Support />,
      },
      {
        path: 'errors',
        element: <Errors />,
      },
      // ADMIN
      {
        path: 'admin',
        element: <Dashboard />,
      },
      {
        path: 'forms',
        element: <Forms />,
      },
      {
        path: 'forms/:id/:type',
        element: <FormTabbed />,
        children: [
          {
            path: 'inquiries',
            element: <FormInquiries />,
          },
          {
            path: 'details',
            element: <FormEdit />,
          },
          {
            path: 'settings',
            element: <FormSettings />,
          },
        ],
      },
      {
        path: 'business-units/org-structure',
        element: <BusinessUnits />,
        children: [
          {
            path: 'list',
            element: <BusinessUnitsList />,
          },
          {
            path: 'chart',
            element: <BusinessUnitsChart />,
          },
        ],
      },
      {
        path: 'business-units/org-structure/details',
        children: [
          {
            path: ':id',
            element: <BusinessUnit />,
            children: [
              {
                path: 'staff',
                element: <BusinessStaffUnits />,
              },
              {
                path: 'jobs',
                element: <JobsView />,
              },
              {
                path: 'divisions',
                element: <DivisionsView />,
              },
            ],
          },
        ],
      },
      {
        path: 'employees',
        element: <CoreEmployees />,
        children: [
          {
            path: 'list',
            element: <StaffUnits />,
          },

          {
            path: 'chart',
            element: <EmployeesTree />,
          },
        ],
      },
      {
        path: 'employees/profile/:id',
        element: <EmployeeView />,
        children: [
          {
            path: 'job-summary',
            element: <EmployeeViewJobInfo />,
          },
          {
            path: 'profile',
            element: <EmployeeViewProfile />,
          },
          {
            path: 'account',
            element: <EmployeeViewAccount />,
          },
          {
            path: 'education',
            element: <EmployeeViewEducation />,
          },
          {
            path: 'evaluation',
            element: <EmployeeViewEvaluation />,
          },
        ],
      },
      {
        path: 'directories',
        element: <Directories />,
        children: [
          {
            path: 'integrations',
            element: <DirectoriesIntegrations />,
          },
          {
            path: 'business-units-types',
            element: <DirectoriesBusinessUnitTypes />,
          },
          {
            path: 'division-types',
            element: <DirectoriesDivisionTypes />,
          },
          {
            path: 'import',
            children: [
              {
                index: true,
                element: <DirectoriesImport />,
              },
              {
                path: 'company',
                element: <ImportCompanies />,
              },
              {
                path: 'jobs',
                element: <ImportJobs />,
              },
              {
                path: 'users',
                element: <ImportUsers />,
              },
            ],
          },
        ],
      },
      {
        path: 'settings',
        element: <Outlet />,
        children: [
          {
            path: 'roles',
            element: <SettingsRoles />,
          },
          {
            path: 'portal',
            element: <PortalSetting />,
          },
          {
            path: 'notifications',
            element: <SettingsNotifications />,
          },
          {
            path: 'languages',
            element: <SettingsLanguages />,
          },
          {
            path: 'email-templates',
            element: <SettingsEmailTemplates />,
          },
          {
            path: 'accounts',
            element: <SettingsUserAccounts />,
          },
          {
            path: 'auth-methods',
            element: <SettingsAuthMethods />,
            children: [
              {
                path: 'ldap',
                element: <SettingsLdap />,
              },
              {
                path: 'system',
                element: <AuthMethodSystem />,
              },
            ],
          },
        ],
      },
      {
        path: 'files',
        element: <Library isCore />,
      },
    ],
  },
];
